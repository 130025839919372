.scaleActive {
  background-color: orange !important;
  color: "white";
}

button.swal-button.swal-button--confirm {
  background: transparent linear-gradient(90deg, #e75d0f 0%, #f19702 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  text-decoration: none;
  padding: 12px 10px;
  border-radius: 20px;
  display: block;
  width: 30%;
  margin: 0 auto;
}
button.swal-button.swal-button--confirm:hover {
  background: #4472c7;
  border-color: #4472c7;
}

.nav-tabs,
.nav-item,
.nav-link,
.rounded-pill {
  border-radius: 10px !important;
}

.chat-box {
  height: 510px;
  overflow-y: scroll;
}
.swal-button:focus {
  box-shadow: none;
}
.swal-button-container {
  margin: 5px;
  display: block;
  position: relative;
  text-align: center;
}

.swal-modal {
  border-radius: 20px;
}

.box-1 {
  background: #fff7eb;
  border: 1px solid #f9cdb0;
  border-radius: 15px;
  padding: 14px 10px;
}
.wh-20 {
  width: 20px;
  height: 20px;
}
.wh-14 {
  width: 14px;
  height: 14px;
}
.wh-30 {
  width: 30px;
  height: 30px;
}
.img-full {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.fs-13 {
  font-size: 13px;
}
.fs-12 {
  font-size: 12px;
}
.fs-10 {
  font-size: 10px;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-grey {
  color: rgb(0 0 0 / 40%);
}
.border-right,
.border-bottom {
  border-color: #f9cdb0 !important;
}
.bg-lightgray-2 {
  background-color: #ececec;
  border: 1px solid #8d8d8d;
  border-radius: 15px;
  padding: 17px 14px;
}
.users-grid-5 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.users-grid-5 > * {
  flex: 0 0 19%;
}

.datePicker input {
  border: none !important;
  padding-top: 0.4em;
  width: 100%;
}
