@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
:root {
  --blue: #5E50F9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #0f1531;
  --blue: #5E50F9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #434a54;
  --gray-light: #aab2bd;
  --gray-lighter: #e8eff4;
  --gray-lightest: #e6e9ed;
  --black: #000000;
  --primary: #E8630E;
  --secondary: #c3bdbd;
  --success: #77DD77;
  --info: #E8630E;
  --warning: #fed713;
  --danger: #ff3860;
  --light: #f8f9fa;
  --dark: #3e4b5b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
[role="button"]{cursor: pointer}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }
.lh-initial {line-height: initial !Important}
.line-height-20 {line-height: 20px !important}
.line-height-22 {line-height: 22px !important}
.line-height-24 {line-height: 24px !important}
.font-12 {font-size:	0.75rem !important}
.font-14 {font-size:	0.875rem !important}
.font-16 {font-size:	1rem !important}
.font-18 {font-size:	1.125rem !important}
.font-20 {font-size:	1.25rem !important}
.font-22 {font-size:	1.375rem !important}
.font-24 {font-size:	1.5rem !important}
.font-26 {font-size:	1.625rem !important}
.font-28 {font-size:	1.75rem !important}
.font-30 {font-size:	1.875rem !important}
.weight-300 {font-weight: 300 !important;}
.weight-400 {font-weight: 400 !important;}
.weight-500 {font-weight: 500 !important;}
.weight-600 {font-weight: 600 !important;}
.weight-700 {font-weight: 700 !important;}
.weight-800 {font-weight: 800 !important;}
.w50 {width: 50px}
.w100 {width: 100px}
.w120 {width: 120px}
.w140 {width: 140px}
.w160 {width: 160px}
.w180 {width: 180px}
.w200 {width: 200px}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #E8630E;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #e8620e;
    text-decoration: underline; }


a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  line-height: 1.2; }


h1, .h1 {
  font-size: 2.5rem; }


h2, .h2 {
  font-size: 2rem; }


h3, .h3 {
  font-size: 1.75rem; }


h4, .h4 {
  font-size: 1.5rem;
	line-height: 1.6rem
}

h5, .h5 {
  font-size: 1.25rem; }


h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);}
.font-bold {
	font-weight: bold
}
.text-default {
	color: #000000 !important;
}
.text-black {
	color: #000000 !important;
}
small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }
	.img-thumbnail-round {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 100px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }


pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1366px; } }

.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }


.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }


.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.9375rem;
    vertical-align: top;
    border-top: 1px solid #ebedf2; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ebedf2; }
  .table tbody + tbody {
    border-top: 2px solid #ebedf2; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #ebedf2; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ebedf2; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }
.table-striped tbody tr:nth-of-type(even) {
  background-color: #FAFAFA; }
.table-striped thead tr {
	background: #1B1D21;
	color: #fff;
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: #FAFAFA; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ebd6ff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #d9b3ff; }

.table-hover .table-primary:hover {
  background-color: #dfbdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #dfbdff; }


.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #eeeded; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #e0dddd; }

.table-hover .table-secondary:hover {
  background-color: #e2e0e0; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e2e0e0; }


.table-success,
.table-success > th,
.table-success > td {
  background-color: #bff2ea; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #88e6d8; }

.table-hover .table-success:hover {
  background-color: #aaeee3; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #aaeee3; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bfdef7; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #87c2f0; }

.table-hover .table-info:hover {
  background-color: #a8d2f4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a8d2f4; }


.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff4bd; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #feea84; }

.table-hover .table-warning:hover {
  background-color: #fff0a4; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff0a4; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffdae2; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #febbc8; }

.table-hover .table-danger:hover {
  background-color: #ffc1ce; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffc1ce; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9cdd1; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9ba1aa; }

.table-hover .table-dark:hover {
  background-color: #bbc0c5; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bbc0c5; }


.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #ebedf2; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }


@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }


.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: 2.6rem;
  padding: 0.6rem 1rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control::placeholder {
    color: #c9c8c8;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }


select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.94rem + 1px);
  padding-bottom: calc(0.94rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1; }

.col-form-label-lg {
  padding-top: calc(0.94rem + 1px);
  padding-bottom: calc(0.94rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.94rem;
  padding-bottom: 0.94rem;
  margin-bottom: 0;
  line-height: 1;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: 2.575rem;
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: 3.175rem;
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }


select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #9c9fa6; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }


.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }


.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }


.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }


.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }


.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
	.form-inline .form-control.md {
		max-width: 75px
	}
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }


.btn {
  display: inline-block;
  font-weight: 400;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 2.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.1875rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
	.btn-bordered-gray {
		border: 2px solid #a6a6a6
	}
	.btn-bordered {
		border: 2px solid #000;
		color: #000
	}
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #343a40;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }


a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background: transparent linear-gradient(90deg, #E75D0F 0%, #F19702 100%) 0% 0% no-repeat padding-box;}
  .btn-primary:hover {
    color: #fff;
    background: transparent linear-gradient(90deg, #F19702 0%, #E75D0F 100%) 0% 0% no-repeat padding-box; }
  .btn-primary:focus, .btn-primary.focus {
    background: transparent linear-gradient(90deg, #F19702 0%, #E75D0F 100%) 0% 0% no-repeat padding-box; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background: transparent linear-gradient(90deg, #F19702 0%, #E75D0F 100%) 0% 0% no-repeat padding-box; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background: transparent linear-gradient(90deg, #F19702 0%, #E75D0F 100%) 0% 0% no-repeat padding-box; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(239,140,4,.2);
      box-shadow: 0 0 0 0.2rem rgba(239,140,4,.2) }


.btn-secondary {
  color: #212529;
  background-color: #c3bdbd;
  border-color: #c3bdbd; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #b1a9a9;
    border-color: #aba2a2; }
  .btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 166, 167, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(171, 166, 167, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #c3bdbd;
    border-color: #c3bdbd; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #aba2a2;
    border-color: #a59c9c; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 166, 167, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(171, 166, 167, 0.5); }


.btn-success {
  color: #212529;
  background-color: #77DD77;
  border-color: #77DD77; }
  .btn-success:hover {
    color: #fff;
    background-color: #17ad97;
    border-color: #15a28d; }
  .btn-success:focus, .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 182, 159, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(28, 182, 159, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #77DD77;
    border-color: #77DD77; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #15a28d;
    border-color: #149783; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 182, 159, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(28, 182, 159, 0.5); }


.btn-info {
  color: #fff;
  background-color: #E8630E;
  border-color: #E8630E; }
  .btn-info:hover {
    color: #fff;
    background-color: #1575c1;
    border-color: #146eb5; }
  .btn-info:focus, .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(60, 156, 231, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(60, 156, 231, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #E8630E;
    border-color: #E8630E; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #146eb5;
    border-color: #1367aa; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(60, 156, 231, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(60, 156, 231, 0.5); }


.btn-warning {
  color: #212529;
  background-color: #fed713;
  border-color: #fed713; }
  .btn-warning:hover {
    color: #212529;
    background-color: #eac301;
    border-color: #ddb901; }
  .btn-warning:focus, .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 188, 22, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(221, 188, 22, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #fed713;
    border-color: #fed713; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ddb901;
    border-color: #d0ae01; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 188, 22, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(221, 188, 22, 0.5); }

.btn-danger {
  color: #212529;
  background-color: #ff3860;
  border-color: #ff3860; }
  .btn-danger:hover {
    color: #fff;
    background-color: #fe5678;
    border-color: #fe496d; }
  .btn-danger:focus, .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 111, 134, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(221, 111, 134, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #212529;
    background-color: #ff3860;
    border-color: #ff3860; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #fe496d;
    border-color: #fe3d63; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 111, 134, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(221, 111, 134, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }


.btn-dark {
  color: #fff;
  background-color: #3e4b5b;
  border-color: #3e4b5b; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2f3844;
    border-color: #29323d; }
  .btn-dark:focus, .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #29323d;
    border-color: #242c35; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(91, 102, 116, 0.5); }


.btn-outline-primary {
  color: #E8630E;
  border-color: #E8630E; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #E8630E;
    border-color: #E8630E; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(182, 109, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(182, 109, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #E8630E;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #E8630E;
    border-color: #E8630E; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(182, 109, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(182, 109, 255, 0.5); }


.btn-outline-secondary {
  color: #c3bdbd;
  border-color: #c3bdbd; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #c3bdbd;
    border-color: #c3bdbd; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(195, 189, 189, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(195, 189, 189, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #c3bdbd;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #c3bdbd;
    border-color: #c3bdbd; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(195, 189, 189, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(195, 189, 189, 0.5); }


.btn-outline-success {
  color: #77DD77;
  border-color: #77DD77; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #77DD77;
    border-color: #77DD77; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 207, 180, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(27, 207, 180, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #77DD77;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #77DD77;
    border-color: #77DD77; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 207, 180, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(27, 207, 180, 0.5); }


.btn-outline-info {
  color: #E8630E;
  border-color: #E8630E; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #E8630E;
    border-color: #E8630E; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 138, 227, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(25, 138, 227, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #E8630E;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #E8630E;
    border-color: #E8630E; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 138, 227, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(25, 138, 227, 0.5); }


.btn-outline-warning {
  color: #fed713;
  border-color: #fed713; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #fed713;
    border-color: #fed713; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 215, 19, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(254, 215, 19, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fed713;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fed713;
    border-color: #fed713; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 215, 19, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(254, 215, 19, 0.5); }


.btn-outline-danger {
  color: #ff3860;
  border-color: #ff3860; }
  .btn-outline-danger:hover {
    color: #212529;
    background-color: #ff3860;
    border-color: #ff3860; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 124, 150, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(254, 124, 150, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff3860;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #212529;
    background-color: #ff3860;
    border-color: #ff3860; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 124, 150, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(254, 124, 150, 0.5); }


.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }


.btn-outline-dark {
  color: #3e4b5b;
  border-color: #3e4b5b; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3e4b5b;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }


.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #e8620e;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }


.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 3rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
height: 60px	}


.btn-sm, .btn-group-sm > .btn {
  padding: 0.45rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }


input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ebedf2;
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #ebedf2; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #343a40;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }


.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #343a40;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #343a40; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }


.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.875rem;
  padding-left: 1.875rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6075rem;
  padding-left: 0.6075rem; }


.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 2.25rem;
  padding-left: 2.25rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }


.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }


.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.94rem 1.375rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 2px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }


.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: 3.175rem; }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: 2.575rem; }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }


.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: -0.12em;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: -0.12em;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }


.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }


.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }


.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }


.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }


.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1.25rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }


.nav-tabs {
	border: 1px solid #1B1D2126;
	border-radius: 50px;
	background: #fff;
}
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #ebedf2 #ebedf2 #ebedf2; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #343a40;
    background-color: #ffffff;
    border-color: #ebedf2 #ebedf2 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }


.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }


.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }


.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    /* position: static; */
    float: none; }


.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }


@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }


@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }


@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }


.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }


.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }


.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }


.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  background: #d9d9d9;
  border-color: #b1b2b5;
  color: #000 !important;}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }


.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; 
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }
a.card-body:hover {
	text-decoration: none
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: #FFF7EB;
	border: 1px solid #F9CDB0;
	border-radius: 15px;
	box-shadow: none;
}
.card h1 {
	font-size: 36px;
	line-height: 36px;
}
.card .card-body .icon:before {
	font-size: 60px;
}
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem; }


.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
	padding: 1.5rem 1.5rem 1.3rem;
	margin-bottom: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	margin: -25px -15px 0 -15px;
	font-weight: 500;
}
  /* .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; } */
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }


.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }


.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
      .card-group > .card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }


.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden;
	box-shadow: none;
	margin-bottom: 10px
}
  /* .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; } */
  /* .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; } */
  .accordion > .card:first-of-type {
    border-bottom: 0;
   }
  /* .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; } */
  .accordion > .card .card-header {
    margin-bottom: -1px;
		border: none;
	}
	.accordion > .card .card-header i.mdi {
    float: right;
    font-size: 30px;
    line-height: 18px;
		cursor: pointer;
		-webkit-transition: all .5s ease;
			-moz-transition: all .5s ease;
				transition: all .5s ease
	}
	
	.accordion > .card .card-header i.mdi[aria-expanded="true"] {
    transform: rotate(-180deg);
	}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.56rem 1.13rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
.breadcrumb-item + .breadcrumb-item::before {
	display: inline-block;
	content: "\f142";
	font-family: "Material Design Icons";
	font-size: 27px;
	vertical-align: middle;
	line-height: 20px;
	color: #1B1D217F;
	margin-right: 5px;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #495057; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	align-items: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
	display: block;
	margin: 0;
	padding: 0.438rem 0.75rem;
	line-height: 1.35;
	color: #000;
	border: none;
	font-weight: 500;
	font-size: 16px;
}
  .page-link:hover, .page-link:focus, .page-item.active .page-link {
    z-index: 2;
    color: #E8630E;
    text-decoration: none;
	}
.page-item .page-link .mdi {
	margin-left: 0;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
	font-size: 26px;
	line-height: 35px;
	height: 35px;
	padding: 0;
	color: #1B1D217F;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #E8630E; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #9d3aff; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(182, 109, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(182, 109, 255, 0.5); }


.badge-secondary {
  color: #212529;
  background-color: #c3bdbd; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #aba2a2; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(195, 189, 189, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(195, 189, 189, 0.5); }

.badge-success, .preview-list .preview-item .preview-thumbnail .badge.badge-online {
  color: #212529;
  background-color: #77DD77; }
  a.badge-success:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:hover, a.badge-success:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus {
    color: #212529;
    background-color: #15a28d; }
  a.badge-success:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus, a.badge-success.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-online {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 207, 180, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(27, 207, 180, 0.5); }

.badge-info, .preview-list .preview-item .preview-thumbnail .badge.badge-offline {
  color: #fff;
  background-color: #E8630E; }
  a.badge-info:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:hover, a.badge-info:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus {
    color: #fff;
    background-color: #146eb5; }
  a.badge-info:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus, a.badge-info.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-offline {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 138, 227, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(25, 138, 227, 0.5); }

.badge-warning, .preview-list .preview-item .preview-thumbnail .badge.badge-busy {
  color: #212529;
  background-color: #fed713; }
  a.badge-warning:hover, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:hover, a.badge-warning:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus {
    color: #212529;
    background-color: #ddb901; }
  a.badge-warning:focus, .preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus, a.badge-warning.focus, .preview-list .preview-item .preview-thumbnail a.focus.badge.badge-busy {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 215, 19, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(254, 215, 19, 0.5); }

.badge-danger {
  color: #212529;
  background-color: #ff3860; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #212529;
    background-color: #fe496d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 124, 150, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(254, 124, 150, 0.5); }


.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }


.badge-dark {
  color: #fff;
  background-color: #3e4b5b; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #29323d; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(62, 75, 91, 0.5); }

    .badge-dark-red{
      background-color: #B41616;
    }
    .badge-blue{
      background-color: #168EB4;
    }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }


.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }


.alert-primary {
  color: #5f3985;
  background-color: #f0e2ff;
  border-color: #ebd6ff; }
  .alert-primary hr {
    border-top-color: #dfbdff; }
  .alert-primary .alert-link {
    color: #462a61; }

.alert-secondary {
  color: #656262;
  background-color: #f3f2f2;
  border-color: #eeeded; }
  .alert-secondary hr {
    border-top-color: #e2e0e0; }
  .alert-secondary .alert-link {
    color: #4b4949; }


.alert-success {
  color: #0e6c5e;
  background-color: #d1f5f0;
  border-color: #bff2ea; }
  .alert-success hr {
    border-top-color: #aaeee3; }
  .alert-success .alert-link {
    color: #083f37; }

.alert-info {
  color: #0d4876;
  background-color: #d1e8f9;
  border-color: #bfdef7; }
  .alert-info hr {
    border-top-color: #a8d2f4; }
  .alert-info .alert-link {
    color: #082c48; }

.alert-warning {
  color: #84700a;
  background-color: #fff7d0;
  border-color: #fff4bd; }
  .alert-warning hr {
    border-top-color: #fff0a4; }
  .alert-warning .alert-link {
    color: #554806; }


.alert-danger {
  color: #84404e;
  background-color: #ffe5ea;
  border-color: #ffdae2; }
  .alert-danger hr {
    border-top-color: #ffc1ce; }
  .alert-danger .alert-link {
    color: #622f3a; }


.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }


.alert-dark {
  color: #20272f;
  background-color: #d8dbde;
  border-color: #c9cdd1; }
  .alert-dark hr {
    border-top-color: #bbc0c5; }
  .alert-dark .alert-link {
    color: #0b0e11; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none; } }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }


.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }


.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }


@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }


@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }


@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #5f3985;
  background-color: #ebd6ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #5f3985;
    background-color: #dfbdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #5f3985;
    border-color: #5f3985; }


.list-group-item-secondary {
  color: #656262;
  background-color: #eeeded; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #656262;
    background-color: #e2e0e0; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #656262;
    border-color: #656262; }

.list-group-item-success {
  color: #0e6c5e;
  background-color: #bff2ea; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0e6c5e;
    background-color: #aaeee3; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #0e6c5e;
    border-color: #0e6c5e; }


.list-group-item-info {
  color: #0d4876;
  background-color: #bfdef7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0d4876;
    background-color: #a8d2f4; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #0d4876;
    border-color: #0d4876; }

.list-group-item-warning {
  color: #84700a;
  background-color: #fff4bd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #84700a;
    background-color: #fff0a4; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #84700a;
    border-color: #84700a; }

.list-group-item-danger {
  color: #84404e;
  background-color: #ffdae2; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #84404e;
    background-color: #ffc1ce; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #84404e;
    border-color: #84404e; }


.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #818182;
    border-color: #818182; }


.list-group-item-dark {
  color: #20272f;
  background-color: #c9cdd1; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #20272f;
    background-color: #bbc0c5; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #20272f;
    border-color: #20272f; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }


button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.4s ease;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none; }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 20px); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 20px);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }


.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 20px); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 20px);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }
button.close.topRight, button.close.topRight:hover {
	position: absolute;
	top: -18px;
	right: -18px;
	width: 40px;
	height: 40px;
	opacity: 1 !important;
	background: #fff;
	border-radius: 50px;
	cursor: pointer;
	z-index: 1;
}
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ffffff;
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }


.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #ebedf2;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 3px 15px;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 28px;
	}
	.modal-header .close span {
    font-size: 45px;
    font-weight: 300;
	}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #ebedf2;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }


.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 60px); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 60px); }
  .modal-dialog-centered {
    min-height: calc(100% - 60px); }
    .modal-dialog-centered::before {
      height: calc(100vh - 60px); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 90%; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }


.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }


.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }


.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }


.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }


.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }


.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }


.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
	background-color: #E8630E !important; }
.bg-light-gray {
	background: #eeeeee;
}
.border-2-dark {
	border: 2px solid #dedede;
}
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #9d3aff !important; }

.bg-secondary {
  background-color: #c3bdbd !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #aba2a2 !important; }

.bg-success {
  background-color: #77DD77 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #15a28d !important; }

.bg-info {
  background-color: #E8630E !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #146eb5 !important; }

.bg-warning {
  background-color: #fed713 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ddb901 !important; }

.bg-danger {
  background-color: #ff3860 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #fe496d !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #3e4b5b !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #29323d !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border, .loader-demo-box {
  border: 1px solid #ebedf2 !important; }

.border-top {
  border-top: 1px solid #ebedf2 !important; }

.border-right {
  border-right: 1px solid #ebedf2 !important; }

.border-bottom {
  border-bottom: 1px solid #ebedf2 !important; }

.border-left {
  border-left: 1px solid #ebedf2 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #E8630E !important; }

.border-secondary, .loader-demo-box {
  border-color: #c3bdbd !important; }

.border-success {
  border-color: #77DD77 !important; }

.border-info {
  border-color: #E8630E !important; }

.border-warning {
  border-color: #fed713 !important; }

.border-danger {
  border-color: #ff3860 !important; }
.border-violet {
	border-color: #A13BE0 !Important }
.border-orange {
	border-color: #FF8102 !Important }
.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #3e4b5b !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded, .loader-demo-box {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }
	
.rounded-xl {
  border-radius: 2rem !important; }
	
.rounded-xl-top {
  border-radius: .6rem .6rem 0 0 !important; }
	
.rounded-xl-bottom {
  border-radius: 0 0 .6rem .6rem !important; }

.rounded-circle {
  border-radius: 50% !important; }
/* 
.rounded-pill {
  border-radius: 50rem !important; } */

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .page-header, .loader-demo-box, .list-wrapper ul li, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start, .list-wrapper ul li {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between, .page-header {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center, .page-header, .loader-demo-box, .list-wrapper ul li, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }


.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0, .demo-modal .modal-dialog,
.my-0 {
  margin-top: 0 !important; }

.mr-0, .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0, .rtl .preview-list .preview-item .preview-thumbnail .preview-icon i, .rtl .list-wrapper .remove, .rtl .navbar .navbar-menu-wrapper .search-field,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .template-demo > .btn-group,
.template-demo > .btn-group-vertical, .template-demo .circle-progress,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .template-demo > .btn-group,
.template-demo > .btn-group-vertical, .template-demo > .dropdown,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .btn-toolbar .btn-group + .btn-group,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .template-demo > .btn, .template-demo > .btn-toolbar,
.my-3 {
  margin-top: 1rem !important; }

.mr-3, .template-demo > .btn, .template-demo > .btn-toolbar,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3, .template-demo .circle-progress-block,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4, .rtl .navbar .navbar-menu-wrapper .search-field,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }
	
.mb-45,
.my-45 {
  margin-bottom: 2.2rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }
.mt-45, .my-45 {
    margin-top: 2.2rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0, .card-revenue-table .revenue-item:first-child,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0, .card-revenue-table .revenue-item:last-child,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2,
.template-demo .circle-progress-block {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2,
.template-demo .circle-progress-block {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3,
.card-revenue-table .revenue-item {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3,
.card-revenue-table .revenue-item {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto, .rtl .list-wrapper .remove,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto, .list-wrapper .remove,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white, .navbar.navbar-primary .navbar-menu-wrapper .nav-profile-text p, .navbar.navbar-secondary .navbar-menu-wrapper .nav-profile-text p, .navbar.navbar-success .navbar-menu-wrapper .nav-profile-text p, .navbar.navbar-info .navbar-menu-wrapper .nav-profile-text p, .navbar.navbar-warning .navbar-menu-wrapper .nav-profile-text p, .navbar.navbar-danger .navbar-menu-wrapper .nav-profile-text p, .navbar.navbar-light .navbar-menu-wrapper .nav-profile-text p, .navbar.navbar-dark .navbar-menu-wrapper .nav-profile-text p {
  color: #ffffff !important; }

.text-primary, .list-wrapper .completed .remove {
  color: #E8630E !important; }

a.text-primary:hover, .list-wrapper .completed a.remove:hover, a.text-primary:focus, .list-wrapper .completed a.remove:focus {
  color: #9021ff !important; }

.text-secondary, .list-wrapper .remove {
  color: #c3bdbd !important; }

a.text-secondary:hover, .list-wrapper a.remove:hover, a.text-secondary:focus, .list-wrapper a.remove:focus {
  color: #9f9595 !important; }

.text-success {
  color: #77DD77 !important; }

a.text-success:hover, a.text-success:focus {
  color: #77DD77 !important; }

.text-info {
  color: #E8630E !important; }

a.text-info:hover, a.text-info:focus {
  color: #E8630E !important; }

.text-warning {
  color: #fed713 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #c4a301 !important; }

.text-danger {
  color: #ff3860 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #fd3059 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #3e4b5b !important; }
	
.text-violet {
	color: #A13BE0 !Important; }
	
.text-orange {
	color: #FF8102 !Important; }
	
a.text-dark:hover, a.text-dark:focus {
  color: #1f262e !important; }

.text-body {
  color: #343a40 !important; }

.text-muted, .preview-list .preview-item .preview-item-content p .content-category {
  color: #9c9fa6 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #ebedf2; }
  .table .thead-dark th {
    color: inherit;
    border-color: #ebedf2; } }

/*-------------------------------------------------------------------*/
/* === Template mixins === */
/* Miscellaneous Mixins */
.list-wrapper ul li .form-check, .list-wrapper ul li .form-check .form-check-label {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap; }

/* Animation Mixins */
@-webkit-keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); } }
@keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); } }

.dropdownAnimation, .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  -webkit-animation-name: dropdownAnimation;
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

.infinite-spin {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

/*-------------------------------------------------------------------*/
/* === Core Styles === */
/* Reset Styles */
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden; }

.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none; }

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
  text-shadow: none; }

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0; }

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline-style: none; }

textarea {
  resize: none;
  overflow-x: hidden; }

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0; }

.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0; }

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: 0; }

a:focus,
input:focus {
  border-color: transparent;
  outline: none; }

.text-twitter {
  color: #2caae1; }

.text-facebook {
  color: #3b579d; }

.text-google {
  color: #dc4a38; }

.text-linkedin {
  color: #0177b5; }

.text-pinterest {
  color: #cc2127; }

.text-youtube {
  color: #e52d27; }

.text-github {
  color: #333333; }

.text-behance {
  color: #1769ff; }

.text-dribbble {
  color: #ea4c89; }

.text-reddit {
  color: #ff4500; }

.bg-twitter {
  background: #2caae1; }

.bg-facebook {
  background: #3b579d; }

.bg-google {
  background: #dc4a38; }

.bg-linkedin {
  background: #0177b5; }

.bg-pinterest {
  background: #cc2127; }

.bg-youtube {
  background: #e52d27; }

.bg-github {
  background: #333333; }

.bg-behance {
  background: #1769ff; }

.bg-dribbble {
  background: #ea4c89; }

.bg-reddit {
  background: #ff4500; }

/* Typography */
body {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Poppins', sans-serif; }

p {
  font-size: 0.875rem; }

h1, .h1 {
  font-size: 2.19rem; }

h2, .h2 {
  font-size: 1.88rem; }

h3, .h3 {
  font-size: 1.56rem; }

h4, .h4 {
  font-size: 1.2rem;
	line-height: 1.6rem
}

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: .9375rem; }

p {
  font-size: .9375rem;
  line-height: 1.5; }

.display-1 {
  font-size: 3.75rem; }
  @media (max-width: 991px) {
    .display-1 {
      font-size: 3rem; } }

.display-2 {
  font-size: 3.125rem; }
  @media (max-width: 991px) {
    .display-2 {
      font-size: 2.5rem; } }

.display-3 {
  font-size: 2.5rem; }
  @media (max-width: 991px) {
    .display-3 {
      font-size: 2rem; } }

.display-4 {
  font-size: 1.875rem; }
  @media (max-width: 991px) {
    .display-4 {
      font-size: 1.5rem; } }

.display-5 {
  font-size: 1.25rem; }
  @media (max-width: 991px) {
    .display-5 {
      font-size: 1rem; } }

.blockquote {
  padding: 1.25rem;
  border: 1px solid #ebedf2; }

address p {
  margin-bottom: 0; }

.blockquote-primary {
  border-color: #E8630E; }
  .blockquote-primary .blockquote-footer {
    color: #E8630E; }

.blockquote-secondary {
  border-color: #c3bdbd; }
  .blockquote-secondary .blockquote-footer {
    color: #c3bdbd; }

.blockquote-success {
  border-color: #77DD77; }
  .blockquote-success .blockquote-footer {
    color: #77DD77; }

.blockquote-info {
  border-color: #E8630E; }
  .blockquote-info .blockquote-footer {
    color: #E8630E; }

.blockquote-warning {
  border-color: #fed713; }
  .blockquote-warning .blockquote-footer {
    color: #fed713; }

.blockquote-danger {
  border-color: #ff3860; }
  .blockquote-danger .blockquote-footer {
    color: #ff3860; }

.blockquote-light {
  border-color: #f8f9fa; }
  .blockquote-light .blockquote-footer {
    color: #f8f9fa; }

.blockquote-dark {
  border-color: #3e4b5b; }
  .blockquote-dark .blockquote-footer {
    color: #3e4b5b; }

.error-page h1 {
  font-size: 12rem; }
  @media (max-width: 991px) {
    .error-page h1 {
      font-size: 8rem; } }

.icon-lg {
  font-size: 2.5rem; }

.icon-md {
  font-size: 1.875rem; }

.icon-sm {
  font-size: 1rem; }

/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; }

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto; }

.container-scroller {
  overflow: hidden; }

pre {
  background: #e8eff4;
  padding: 15px;
  font-size: 14px; }

code {
  padding: 5px;
  color: #ff3860;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  border-radius: 4px; }

.page-header {
  margin: 0 0 1.5rem 0; }
  .page-header .breadcrumb {
    border: 0;
    margin-bottom: 0; }

.page-title {
  color: #343a40;
  font-size: 1.3rem;
  margin-bottom: 0; }
  .page-title .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    -webkit-box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35); }
    .page-title .page-title-icon i {
      font-size: .9375rem;
      line-height: 36px; }

/* Footer */
.footer {
  background: #fff;
  border-top: 1px solid #e7dee9;
  padding: 20px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: 'Poppins', sans-serif; }
  .footer a {
    color: #77DD77;
    font-size: inherit; }
  @media (max-width: 991px) {
    .footer {
      margin-left: 0;
      width: 100%; } }

/* Utilities */
.grid-margin, .purchase-popup {
  margin-bottom: 1.8rem; }

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0; } }

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0; } }

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0; } }

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0; } }

.img-lg {
  width: 92px;
  height: 92px; }

.img-sm {
  width: 43px;
  height: 43px; }

.img-xs {
  width: 37px;
  height: 37px; }

.img-ss, .image-grouped .text-avatar,
.image-grouped img {
  width: 35px;
  height: 35px; }

.stretch-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch; }
  .stretch-card > .card {
    width: 100%;
    min-width: 100%; }

@media (min-width: 576px) {
  .border-right-sm {
    border-right: 1px solid #ebedf2; } }

@media (min-width: 768px) {
  .border-right-md {
    border-right: 1px solid #ebedf2; } }

@media (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid #ebedf2; } }

@media (min-width: 576px) {
  .border-left-sm {
    border-left: 1px solid #ebedf2; } }

@media (min-width: 768px) {
  .border-left-md {
    border-left: 1px solid #ebedf2; } }

@media (min-width: 992px) {
  .border-left-lg {
    border-left: 1px solid #ebedf2; } }

.text-gray, .card .card-subtitle {
  color: #8c8c8c; }

.text-black {
  color: #000; }

.text-small {
  font-size: 12px; }

.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }
.image-grouped {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .image-grouped .text-avatar,
  .image-grouped img {
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-property: "box-shadow", "z-index", "transform", "border-width", "box-shadow", "transform";
    transition-property: "box-shadow", "z-index", "transform", "border-width", "box-shadow", "transform";
    transition-property: "box-shadow", "z-index", "transform", "border-width"; }
    .image-grouped .text-avatar:first-child,
    .image-grouped img:first-child {
      margin-left: 0; }
    .image-grouped .text-avatar:hover,
    .image-grouped img:hover {
      z-index: 1;
      -webkit-box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
      border-width: 0; }
  .image-grouped .text-avatar {
    color: #E8630E;
    font-size: 11px;
    font-weight: 600; }

.aligner-wrapper {
  position: relative; }
  .aligner-wrapper .absolute {
    position: absolute; }
    .aligner-wrapper .absolute.absolute-center {
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto; }
    .aligner-wrapper .absolute.left {
      left: 0; }
    .aligner-wrapper .absolute.right {
      right: 0; }
    .aligner-wrapper .absolute.bottom {
      bottom: 0; }
    .aligner-wrapper .absolute.top {
      top: 0; }

.v-strock-1 {
  width: 2px; }

.v-strock-2 {
  width: 3px; }

.v-strock-3 {
  width: 4px; }

.v-strock-4 {
  width: 5px; }

.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%; }
  .dot-indicator.dot-indicator-sm {
    width: 6px;
    height: 6px; }

.bg-gradient-primary {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff)) !important;
  background: linear-gradient(to right, #da8cff, #9a55ff) !important; }

.bg-gradient-secondary {
  background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96)) !important;
  background: linear-gradient(to right, #e7ebf0, #868e96) !important; }

.bg-gradient-success {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae)) !important;
  background: linear-gradient(to right, #84d9d2, #07cdae) !important; }

.bg-gradient-info {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf)) !important;
  background: linear-gradient(to right, #90caf9, #047edf 99%) !important; }

.bg-gradient-warning {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500)) !important;
  background: linear-gradient(to right, #f6e384, #ffd500) !important; }

.bg-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096)) !important;
  background: linear-gradient(to right, #ffbf96, #fe7096) !important; }

.bg-gradient-light {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9)) !important;
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9) !important; }

.bg-gradient-dark {
  background: linear-gradient(89deg, #5e7188, #3e4b5b) !important; }

/* Demo Styles */
.template-demo .slider-wrap {
  height: 100px; }

.template-demo .progress {
  margin-top: 1.5rem; }

.template-demo > h2,
.template-demo > h3,
.template-demo > h4,
.template-demo > h5,
.template-demo > h6,
.template-demo > h1 {
  border-top: 1px solid #ebedf2;
  padding: 0.5rem 0 0; }

.template-demo .ul-slider.noUi-horizontal {
  margin-top: 2rem; }

.template-demo .ul-slider.noUi-vertical {
  margin-right: 2rem; }

.template-demo > .dropdown {
  display: inline-block;
  margin-bottom: 0.5rem; }

.template-demo nav .breadcrumb {
  margin-bottom: 1.375rem; }

.template-demo nav:last-child .breadcrumb {
  margin-bottom: 0; }

.template-demo .editable-form > .form-group {
  border-bottom: 1px solid #ebedf2;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem; }

.template-demo .circle-progress {
  padding: 15px; }

.demo-modal {
  position: static;
  display: block; }
  .demo-modal .modal-dialog.modal-lg {
    max-width: 100%; }

.loader-demo-box {
  width: 100%;
  height: 200px; }

.dropdown-menu-static-demo {
  height: 250px;
  margin-bottom: 20px; }

.rounded-legend ul li {
  list-style-type: none;
  color: #9c9fa6;
  font-size: .75rem; }
  .rounded-legend ul li .legend-dots {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: .5rem; }
    .rtl .rounded-legend ul li .legend-dots {
      margin-left: .5rem; }

.rounded-legend.legend-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .rounded-legend.legend-horizontal ul li {
    display: inline-block;
    margin-right: 1.5rem; }
    .rtl .rounded-legend.legend-horizontal ul li {
      margin-right: auto;
      margin-left: 1.5rem; }

.rounded-legend.legend-top-right ul {
  float: right; }
  .rtl .rounded-legend.legend-top-right ul {
    float: left; }

.rounded-legend.legend-vertical ul li {
  margin-top: 1rem; }

/* Dashboard */
.card-statistics .highlight-icon {
  height: 53px;
  width: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px; }
  .card-statistics .highlight-icon i {
    font-size: 27px; }

.card-statistics .card-col {
  border-right: 1px solid #ebedf2; }
  .card-statistics .card-col:last-child {
    border-right: none; }
  @media (max-width: 767.98px) {
    .card-statistics .card-col {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      border: none; }
      .card-statistics .card-col:first-child:after, .card-statistics .card-col:first-child:before, .card-statistics .card-col:nth-child(2):after, .card-statistics .card-col:nth-child(2):before, .card-statistics .card-col:nth-child(3):after, .card-statistics .card-col:nth-child(3):before {
        content: "";
        position: absolute;
        background: #ebedf2; }
      .card-statistics .card-col:first-child:before {
        bottom: 0;
        width: 94.2%;
        height: 1px;
        right: 0; }
      .card-statistics .card-col:first-child:after {
        bottom: 0;
        width: 1px;
        height: 100%;
        right: 0; }
      .card-statistics .card-col:nth-child(2):before {
        bottom: 0;
        width: 94.2%;
        height: 1px;
        left: 0; }
      .card-statistics .card-col:nth-child(3):before {
        width: 1px;
        height: 100%;
        right: 0; } }

.card-revenue-table .revenue-item {
  border-bottom: 1px solid #ebedf2; }
  .card-revenue-table .revenue-item:last-child {
    border-bottom: 0; }
  .card-revenue-table .revenue-item .revenue-desc {
    margin-right: auto;
    width: 80%; }
    .card-revenue-table .revenue-item .revenue-desc p {
      margin-bottom: 0; }
  .card-revenue-table .revenue-item .revenue-amount {
    margin-left: auto;
    width: 40%; }
    .card-revenue-table .revenue-item .revenue-amount p {
      font-size: 1.25rem;
      font-family: "roboto", sans-serif;
      font-weight: 600;
      text-align: right; }
      .rtl .card-revenue-table .revenue-item .revenue-amount p {
        text-align: left; }

.card-revenue {
  background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
  background-size: cover;
  color: #ffffff; }
  .card-revenue .highlight-text {
    font-size: 1.875rem;
    font-family: "roboto", sans-serif;
    font-weight: 500; }
  .card-revenue .badge {
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 1.125rem;
    padding: 0.5rem 1.25rem; }

.product-chart-wrapper {
  height: 92%; }

#dashboardTrendingProgress {
  width: 60px; }

.dashboard-bar-chart-legend .col {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .dashboard-bar-chart-legend .col .bg {
    margin-left: auto;
    margin-right: auto;
    height: 5px;
    width: 30px;
    display: block;
    margin-top: 5px; }
  .dashboard-bar-chart-legend .col:nth-child(1) .bg {
    background: #E8630E; }
  .dashboard-bar-chart-legend .col:nth-child(2) .bg {
    background: #E8630E; }
  .dashboard-bar-chart-legend .col:nth-child(3) .bg {
    background: #ff3860; }

.intro-banner {
  background: #dbe4ec;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  border-radius: 2px; }
  .intro-banner .banner-image {
    width: 24%;
    max-width: 100%; }
    .intro-banner .banner-image img {
      display: block;
      margin: auto; }
  .intro-banner .content-area {
    width: 58%;
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end; }

.sales-mini-tabs {
  border-bottom: none; }
  .sales-mini-tabs .nav-item .nav-link {
    border: none;
    font-weight: 500;
    padding: 5px 15px;
    border-radius: 2px;
    -webkit-transition-duration: 0.7s;
    transition-duration: 0.7s;
    -webkit-transition-property: "color";
    transition-property: "color"; }
    .sales-mini-tabs .nav-item .nav-link.active {
      font-weight: 600;
      background: white;
      color: #E8630E;
    
    }

.purchase-popup {
  background: #fff;
  padding: 15px 20px;
  border-radius: 3px; }
  .purchase-popup .btn {
    margin-right: 20px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 5px;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s; }
    .purchase-popup .btn.download-button {
      background: rgba(249, 249, 249, 0.7);
      color: #969292;
      border: 1px solid #d7d7d7; }
    .purchase-popup .btn.purchase-button {
      background-color: rgba(172, 50, 228, 0.9);
      background-image: -owg-linear-gradient(to right, #da8cff, #9a55ff);
      background-image: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
      background-image: linear-gradient(to right, #da8cff, #9a55ff);
      color: #ffffff;
      border: none;
      line-height: 1;
      vertical-align: middle; }
  .purchase-popup p {
    margin-bottom: auto;
    margin-top: auto;
    color: #7e7e7e;
    font-weight: 400;
    vertical-align: middle;
    line-height: 1; }
  .purchase-popup i {
    vertical-align: middle;
    line-height: 1;
    margin: auto 0;
    color: #b1b1b1; }

/*-------------------------------------------------------------------*/
/* === Components === */
/* Badges */
.badge {
	border-radius: 5rem;
	font-size: 12px;
	font-weight: initial;
	line-height: 1;
	padding: .2rem .5rem;
	font-family: 'Poppins', sans-serif;
}
  .badge.badge-pill {
    border-radius: 10rem; }

/*Badge variations*/
.badge-primary {
  border: 1px solid #E8630E;
  color: #ffffff; }

.badge-secondary {
  border: 1px solid #c3bdbd;
  color: #ffffff; }

.badge-success, .preview-list .preview-item .preview-thumbnail .badge.badge-online {
  border: 1px solid #77DD77;
  color: #ffffff; }

.badge-info, .preview-list .preview-item .preview-thumbnail .badge.badge-offline {
  border: 1px solid #E8630E;
  color: #ffffff; }

.badge-warning, .preview-list .preview-item .preview-thumbnail .badge.badge-busy {
  border: 1px solid #fed713;
  color: #ffffff; }

.badge-danger {
  border: 1px solid #ff3860;
  color: #ffffff; }

.badge-light {
  border: 1px solid #f8f9fa;
  color: #ffffff; }

.badge-dark {
  border: 1px solid #3e4b5b;
  color: #ffffff; }

/*Badge gradient variations*/
.badge-gradient-primary {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #ffffff; }

.badge-gradient-secondary {
  background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96));
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #ffffff; }

.badge-gradient-success {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff; }

.badge-gradient-info {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff; }

.badge-gradient-warning {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #ffffff; }

.badge-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff; }

.badge-gradient-light {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #ffffff; }

.badge-gradient-dark {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #ffffff; }

/*Badge outlined variations*/
.badge-outline-primary {
  color: #E8630E;
  border: 1px solid #E8630E; }

.badge-outline-secondary {
  color: #c3bdbd;
  border: 1px solid #c3bdbd; }

.badge-outline-success {
  color: #77DD77;
  border: 1px solid #77DD77; }

.badge-outline-info {
  color: #E8630E;
  border: 1px solid #E8630E; }

.badge-outline-warning {
  color: #fed713;
  border: 1px solid #fed713; }

.badge-outline-danger {
  color: #ff3860;
  border: 1px solid #ff3860; }

.badge-outline-light {
  color: #f8f9fa;
  border: 1px solid #f8f9fa; }

.badge-outline-dark {
  color: #3e4b5b;
  border: 1px solid #3e4b5b; }

/* Bootstrap Progress */
.progress {
  border-radius: 3px;
  height: 8px; }
  .progress .progress-bar {
    border-radius: 3px; }
  .progress.progress-sm {
    height: 0.375rem; }
  .progress.progress-md {
    height: 8px; }
  .progress.progress-lg {
    height: 15px; }
  .progress.progress-xl {
    height: 18px; }

/* Buttons */
.btn {
  font-size: 0.875rem;
  line-height: 1;
  font-family: 'Poppins', sans-serif;
  /* Buttons with only icons */
  /* Buttons with icon and text */ }
  .btn i {
    font-size: 1rem; }
  .btn.btn-rounded {
    border-radius: 50px; }
  .btn.btn-fw {
    min-width: 150px; }
  .btn.btn-sm, .btn-group-sm > .btn {
    font-size: 0.875rem; }
  .btn.btn-lg, .btn-group-lg > .btn {
    font-size: 1.188rem; }
  .btn.btn-xs {
    padding: 0.5rem 0.75rem;
    font-size: 0.625rem; }
  .btn.btn-icon {
    width: 42px;
    height: 42px;
    padding: 0; }
  .btn.btn-icon-text .btn-icon-prepend {
    margin-right: .5rem; }
  .btn.btn-icon-text .btn-icon-append {
    margin-left: .5rem; }
  .btn.btn-social-icon {
    width: 50px;
    height: 50px;
    padding: 0; }

.btn-group .btn + .btn {
  border-left: 0; }

/*social buttons*/
.btn-twitter {
  background: #2caae1;
  color: #ffffff; }
  .btn-twitter:hover, .btn-twitter:focus {
    background: #1b8dbf;
    color: #ffffff; }
  .btn-twitter.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #59bce7; }
    .btn-twitter.btn-social-icon-text i {
      background: #2caae1;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-twitter {
  border: 1px solid #2caae1;
  color: #2caae1; }
  .btn-outline-twitter:hover {
    background: #2caae1;
    color: #ffffff; }

.btn-facebook {
  background: #3b579d;
  color: #ffffff; }
  .btn-facebook:hover, .btn-facebook:focus {
    background: #2d4278;
    color: #ffffff; }
  .btn-facebook.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4e6ebd; }
    .btn-facebook.btn-social-icon-text i {
      background: #3b579d;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-facebook {
  border: 1px solid #3b579d;
  color: #3b579d; }
  .btn-outline-facebook:hover {
    background: #3b579d;
    color: #ffffff; }

.btn-google {
  background: #dc4a38;
  color: #ffffff; }
  .btn-google:hover, .btn-google:focus {
    background: #bf3322;
    color: #ffffff; }
  .btn-google.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #e47163; }
    .btn-google.btn-social-icon-text i {
      background: #dc4a38;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-google {
  border: 1px solid #dc4a38;
  color: #dc4a38; }
  .btn-outline-google:hover {
    background: #dc4a38;
    color: #ffffff; }

.btn-linkedin {
  background: #0177b5;
  color: #ffffff; }
  .btn-linkedin:hover, .btn-linkedin:focus {
    background: #015682;
    color: #ffffff; }
  .btn-linkedin.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #0198e8; }
    .btn-linkedin.btn-social-icon-text i {
      background: #0177b5;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-linkedin {
  border: 1px solid #0177b5;
  color: #0177b5; }
  .btn-outline-linkedin:hover {
    background: #0177b5;
    color: #ffffff; }

.btn-pinterest {
  background: #cc2127;
  color: #ffffff; }
  .btn-pinterest:hover, .btn-pinterest:focus {
    background: #a01a1f;
    color: #ffffff; }
  .btn-pinterest.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #e04046; }
    .btn-pinterest.btn-social-icon-text i {
      background: #cc2127;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-pinterest {
  border: 1px solid #cc2127;
  color: #cc2127; }
  .btn-outline-pinterest:hover {
    background: #cc2127;
    color: #ffffff; }

.btn-youtube {
  background: #e52d27;
  color: #ffffff; }
  .btn-youtube:hover, .btn-youtube:focus {
    background: #c21d17;
    color: #ffffff; }
  .btn-youtube.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ea5955; }
    .btn-youtube.btn-social-icon-text i {
      background: #e52d27;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-youtube {
  border: 1px solid #e52d27;
  color: #e52d27; }
  .btn-outline-youtube:hover {
    background: #e52d27;
    color: #ffffff; }

.btn-github {
  background: #333333;
  color: #ffffff; }
  .btn-github:hover, .btn-github:focus {
    background: #1a1a1a;
    color: #ffffff; }
  .btn-github.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4d4d4d; }
    .btn-github.btn-social-icon-text i {
      background: #333333;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-github {
  border: 1px solid #333333;
  color: #333333; }
  .btn-outline-github:hover {
    background: #333333;
    color: #ffffff; }

.btn-behance {
  background: #1769ff;
  color: #ffffff; }
  .btn-behance:hover, .btn-behance:focus {
    background: #0050e3;
    color: #ffffff; }
  .btn-behance.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4a8aff; }
    .btn-behance.btn-social-icon-text i {
      background: #1769ff;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-behance {
  border: 1px solid #1769ff;
  color: #1769ff; }
  .btn-outline-behance:hover {
    background: #1769ff;
    color: #ffffff; }

.btn-dribbble {
  background: #ea4c89;
  color: #ffffff; }
  .btn-dribbble:hover, .btn-dribbble:focus {
    background: #e51e6b;
    color: #ffffff; }
  .btn-dribbble.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ef7aa7; }
    .btn-dribbble.btn-social-icon-text i {
      background: #ea4c89;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-dribbble {
  border: 1px solid #ea4c89;
  color: #ea4c89; }
  .btn-outline-dribbble:hover {
    background: #ea4c89;
    color: #ffffff; }

.btn-reddit {
  background: #ff4500;
  color: #ffffff; }
  .btn-reddit:hover, .btn-reddit:focus {
    background: #cc3700;
    color: #ffffff; }
  .btn-reddit.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ff6a33; }
    .btn-reddit.btn-social-icon-text i {
      background: #ff4500;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem; }

.btn-outline-reddit {
  border: 1px solid #ff4500;
  color: #ff4500; }
  .btn-outline-reddit:hover {
    background: #ff4500;
    color: #ffffff; }

/* inverse buttons */
.btn-inverse-primary {
  background-color: rgba(182, 109, 255, 0.2);
  background-image: none;
  border-color: rgba(182, 109, 255, 0); }
  .btn-inverse-primary:not(.btn-inverse-light) {
    color: #E8630E; }
  .btn-inverse-primary:hover {
    color: #ffffff;
    background-color: #E8630E;
    border-color: #E8630E; }
  .btn-inverse-primary.focus, .btn-inverse-primary:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(182, 109, 255, 0.5);
    box-shadow: 0 0 0 3px rgba(182, 109, 255, 0.5); }
  .btn-inverse-primary.disabled, .btn-inverse-primary:disabled {
    color: #E8630E;
    background-color: transparent; }
  .btn-inverse-primary.active, .btn-inverse-primary:active,
  .show > .btn-inverse-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #E8630E;
    border-color: #E8630E; }

.btn-primary:not(.btn-light) {
  color: #ffffff; }
  .btn-primary:not(.btn-light):hover, .btn-primary:not(.btn-light):focus, .btn-primary:not(.btn-light):active {
    color: #ffffff; }
  .btn-primary:not(.btn-light):focus, .btn-primary:not(.btn-light):active {
    background: #E8630E;
    border-color: #E8630E; }

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #000; }

.btn-inverse-secondary {
  background-color: rgba(195, 189, 189, 0.2);
  background-image: none;
  border-color: rgba(195, 189, 189, 0); }
  .btn-inverse-secondary:not(.btn-inverse-light) {
    color: #c3bdbd; }
  .btn-inverse-secondary:hover {
    color: #ffffff;
    background-color: #c3bdbd;
    border-color: #c3bdbd; }
  .btn-inverse-secondary.focus, .btn-inverse-secondary:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(195, 189, 189, 0.5);
    box-shadow: 0 0 0 3px rgba(195, 189, 189, 0.5); }
  .btn-inverse-secondary.disabled, .btn-inverse-secondary:disabled {
    color: #c3bdbd;
    background-color: transparent; }
  .btn-inverse-secondary.active, .btn-inverse-secondary:active,
  .show > .btn-inverse-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #c3bdbd;
    border-color: #c3bdbd; }

.btn-secondary:not(.btn-light) {
  color: #ffffff; }
  .btn-secondary:not(.btn-light):hover, .btn-secondary:not(.btn-light):focus, .btn-secondary:not(.btn-light):active {
    color: #ffffff; }
  .btn-secondary:not(.btn-light):focus, .btn-secondary:not(.btn-light):active {
    background: #c3bdbd;
    border-color: #c3bdbd; }

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
  background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96));
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #000; }

.btn-inverse-success {
  background-color: rgba(27, 207, 180, 0.2);
  background-image: none;
  border-color: rgba(27, 207, 180, 0); }
  .btn-inverse-success:not(.btn-inverse-light) {
    color: #77DD77; }
  .btn-inverse-success:hover {
    color: #ffffff;
    background-color: #77DD77;
    border-color: #77DD77; }
  .btn-inverse-success.focus, .btn-inverse-success:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(27, 207, 180, 0.5);
    box-shadow: 0 0 0 3px rgba(27, 207, 180, 0.5); }
  .btn-inverse-success.disabled, .btn-inverse-success:disabled {
    color: #77DD77;
    background-color: transparent; }
  .btn-inverse-success.active, .btn-inverse-success:active,
  .show > .btn-inverse-success.dropdown-toggle {
    color: #ffffff;
    background-color: #77DD77;
    border-color: #77DD77; }

.btn-success:not(.btn-light) {
  color: #ffffff; }
  .btn-success:not(.btn-light):hover, .btn-success:not(.btn-light):focus, .btn-success:not(.btn-light):active {
    color: #ffffff; }
  .btn-success:not(.btn-light):focus, .btn-success:not(.btn-light):active {
    background: #77DD77;
    border-color: #77DD77; }

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #000; }

.btn-inverse-info {
  background-color: rgba(25, 138, 227, 0.2);
  background-image: none;
  border-color: rgba(25, 138, 227, 0); }
  .btn-inverse-info:not(.btn-inverse-light) {
    color: #E8630E; }
  .btn-inverse-info:hover {
    color: #ffffff;
    background-color: #E8630E;
    border-color: #E8630E; }
  .btn-inverse-info.focus, .btn-inverse-info:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(25, 138, 227, 0.5);
    box-shadow: 0 0 0 3px rgba(25, 138, 227, 0.5); }
  .btn-inverse-info.disabled, .btn-inverse-info:disabled {
    color: #E8630E;
    background-color: transparent; }
  .btn-inverse-info.active, .btn-inverse-info:active,
  .show > .btn-inverse-info.dropdown-toggle {
    color: #ffffff;
    background-color: #E8630E;
    border-color: #E8630E; }

.btn-info:not(.btn-light) {
  color: #ffffff; }
  .btn-info:not(.btn-light):hover, .btn-info:not(.btn-light):focus, .btn-info:not(.btn-light):active {
    color: #ffffff; }
  .btn-info:not(.btn-light):focus, .btn-info:not(.btn-light):active {
    background: #E8630E;
    border-color: #E8630E; }

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #000; }

.btn-inverse-warning {
  background-color: rgba(254, 215, 19, 0.2);
  background-image: none;
  border-color: rgba(254, 215, 19, 0); }
  .btn-inverse-warning:not(.btn-inverse-light) {
    color: #fed713; }
  .btn-inverse-warning:hover {
    color: #ffffff;
    background-color: #fed713;
    border-color: #fed713; }
  .btn-inverse-warning.focus, .btn-inverse-warning:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(254, 215, 19, 0.5);
    box-shadow: 0 0 0 3px rgba(254, 215, 19, 0.5); }
  .btn-inverse-warning.disabled, .btn-inverse-warning:disabled {
    color: #fed713;
    background-color: transparent; }
  .btn-inverse-warning.active, .btn-inverse-warning:active,
  .show > .btn-inverse-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #fed713;
    border-color: #fed713; }

.btn-warning:not(.btn-light) {
  color: #ffffff; }
  .btn-warning:not(.btn-light):hover, .btn-warning:not(.btn-light):focus, .btn-warning:not(.btn-light):active {
    color: #ffffff; }
  .btn-warning:not(.btn-light):focus, .btn-warning:not(.btn-light):active {
    background: #fed713;
    border-color: #fed713; }

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #000; }

.btn-inverse-danger {
  background-color: rgba(254, 124, 150, 0.2);
  background-image: none;
  border-color: rgba(254, 124, 150, 0); }
  .btn-inverse-danger:not(.btn-inverse-light) {
    color: #ff3860; }
  .btn-inverse-danger:hover {
    color: #ffffff;
    background-color: #ff3860;
    border-color: #ff3860; }
  .btn-inverse-danger.focus, .btn-inverse-danger:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(254, 124, 150, 0.5);
    box-shadow: 0 0 0 3px rgba(254, 124, 150, 0.5); }
  .btn-inverse-danger.disabled, .btn-inverse-danger:disabled {
    color: #ff3860;
    background-color: transparent; }
  .btn-inverse-danger.active, .btn-inverse-danger:active,
  .show > .btn-inverse-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #ff3860;
    border-color: #ff3860; }

.btn-danger:not(.btn-light) {
  color: #ffffff; }
  .btn-danger:not(.btn-light):hover, .btn-danger:not(.btn-light):focus, .btn-danger:not(.btn-light):active {
    color: #ffffff; }
  .btn-danger:not(.btn-light):focus, .btn-danger:not(.btn-light):active {
    background: #ff3860;
    border-color: #ff3860; }

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #000; }

.btn-inverse-light {
  background-color: rgba(248, 249, 250, 0.2);
  background-image: none;
  border-color: rgba(248, 249, 250, 0); }
  .btn-inverse-light:not(.btn-inverse-light) {
    color: #f8f9fa; }
  .btn-inverse-light:hover {
    color: #ffffff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-inverse-light.focus, .btn-inverse-light:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-inverse-light.disabled, .btn-inverse-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-inverse-light.active, .btn-inverse-light:active,
  .show > .btn-inverse-light.dropdown-toggle {
    color: #ffffff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-light:not(.btn-light) {
  color: #ffffff; }
  .btn-light:not(.btn-light):hover, .btn-light:not(.btn-light):focus, .btn-light:not(.btn-light):active {
    color: #ffffff; }
  .btn-light:not(.btn-light):focus, .btn-light:not(.btn-light):active {
    background: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #000; }

.btn-inverse-dark {
  background-color: rgba(62, 75, 91, 0.2);
  background-image: none;
  border-color: rgba(62, 75, 91, 0); }
  .btn-inverse-dark:not(.btn-inverse-light) {
    color: #3e4b5b; }
  .btn-inverse-dark:hover {
    color: #ffffff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }
  .btn-inverse-dark.focus, .btn-inverse-dark:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(62, 75, 91, 0.5);
    box-shadow: 0 0 0 3px rgba(62, 75, 91, 0.5); }
  .btn-inverse-dark.disabled, .btn-inverse-dark:disabled {
    color: #3e4b5b;
    background-color: transparent; }
  .btn-inverse-dark.active, .btn-inverse-dark:active,
  .show > .btn-inverse-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #3e4b5b;
    border-color: #3e4b5b; }

.btn-dark:not(.btn-light) {
  color: #ffffff; }
  .btn-dark:not(.btn-light):hover, .btn-dark:not(.btn-light):focus, .btn-dark:not(.btn-light):active {
    color: #ffffff; }
  .btn-dark:not(.btn-light):focus, .btn-dark:not(.btn-light):active {
    background: #3e4b5b;
    border-color: #3e4b5b; }

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #000; }

/* gradient buttons */
.btn-gradient-primary {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .btn-gradient-primary:not([disabled]):not(.disabled):active, .btn-gradient-primary:not([disabled]):not(.disabled).active,
  .show > .btn-gradient-primary.dropdown-toggle {
    background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
    background: linear-gradient(to right, #da8cff, #9a55ff); }
  .btn-gradient-primary:hover {
    opacity: .8; }
  .btn-gradient-primary:not(.btn-gradient-light) {
    color: #ffffff; }
    .btn-gradient-primary:not(.btn-gradient-light):hover, .btn-gradient-primary:not(.btn-gradient-light):focus, .btn-gradient-primary:not(.btn-gradient-light):active {
      color: #ffffff; }

.btn-gradient-secondary {
  background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96));
  background: linear-gradient(to right, #e7ebf0, #868e96);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .btn-gradient-secondary:not([disabled]):not(.disabled):active, .btn-gradient-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-gradient-secondary.dropdown-toggle {
    background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96));
    background: linear-gradient(to right, #e7ebf0, #868e96); }
  .btn-gradient-secondary:hover {
    opacity: .8; }
  .btn-gradient-secondary:not(.btn-gradient-light) {
    color: #ffffff; }
    .btn-gradient-secondary:not(.btn-gradient-light):hover, .btn-gradient-secondary:not(.btn-gradient-light):focus, .btn-gradient-secondary:not(.btn-gradient-light):active {
      color: #ffffff; }

.btn-gradient-success {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .btn-gradient-success:not([disabled]):not(.disabled):active, .btn-gradient-success:not([disabled]):not(.disabled).active,
  .show > .btn-gradient-success.dropdown-toggle {
    background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
    background: linear-gradient(to right, #84d9d2, #07cdae); }
  .btn-gradient-success:hover {
    opacity: .8; }
  .btn-gradient-success:not(.btn-gradient-light) {
    color: #ffffff; }
    .btn-gradient-success:not(.btn-gradient-light):hover, .btn-gradient-success:not(.btn-gradient-light):focus, .btn-gradient-success:not(.btn-gradient-light):active {
      color: #ffffff; }

.btn-gradient-info {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .btn-gradient-info:not([disabled]):not(.disabled):active, .btn-gradient-info:not([disabled]):not(.disabled).active,
  .show > .btn-gradient-info.dropdown-toggle {
    background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
    background: linear-gradient(to right, #90caf9, #047edf 99%); }
  .btn-gradient-info:hover {
    opacity: .8; }
  .btn-gradient-info:not(.btn-gradient-light) {
    color: #ffffff; }
    .btn-gradient-info:not(.btn-gradient-light):hover, .btn-gradient-info:not(.btn-gradient-light):focus, .btn-gradient-info:not(.btn-gradient-light):active {
      color: #ffffff; }

.btn-gradient-warning {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #f6e384, #ffd500);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .btn-gradient-warning:not([disabled]):not(.disabled):active, .btn-gradient-warning:not([disabled]):not(.disabled).active,
  .show > .btn-gradient-warning.dropdown-toggle {
    background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
    background: linear-gradient(to right, #f6e384, #ffd500); }
  .btn-gradient-warning:hover {
    opacity: .8; }
  .btn-gradient-warning:not(.btn-gradient-light) {
    color: #ffffff; }
    .btn-gradient-warning:not(.btn-gradient-light):hover, .btn-gradient-warning:not(.btn-gradient-light):focus, .btn-gradient-warning:not(.btn-gradient-light):active {
      color: #ffffff; }

.btn-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .btn-gradient-danger:not([disabled]):not(.disabled):active, .btn-gradient-danger:not([disabled]):not(.disabled).active,
  .show > .btn-gradient-danger.dropdown-toggle {
    background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
    background: linear-gradient(to right, #ffbf96, #fe7096); }
  .btn-gradient-danger:hover {
    opacity: .8; }
  .btn-gradient-danger:not(.btn-gradient-light) {
    color: #ffffff; }
    .btn-gradient-danger:not(.btn-gradient-light):hover, .btn-gradient-danger:not(.btn-gradient-light):focus, .btn-gradient-danger:not(.btn-gradient-light):active {
      color: #ffffff; }

.btn-gradient-light {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .btn-gradient-light:not([disabled]):not(.disabled):active, .btn-gradient-light:not([disabled]):not(.disabled).active,
  .show > .btn-gradient-light.dropdown-toggle {
    background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
    background: linear-gradient(to bottom, #f4f4f4, #e4e4e9); }
  .btn-gradient-light:hover {
    opacity: .8; }
  .btn-gradient-light:not(.btn-gradient-light) {
    color: #ffffff; }
    .btn-gradient-light:not(.btn-gradient-light):hover, .btn-gradient-light:not(.btn-gradient-light):focus, .btn-gradient-light:not(.btn-gradient-light):active {
      color: #ffffff; }

.btn-gradient-dark {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .btn-gradient-dark:not([disabled]):not(.disabled):active, .btn-gradient-dark:not([disabled]):not(.disabled).active,
  .show > .btn-gradient-dark.dropdown-toggle {
    background: linear-gradient(89deg, #5e7188, #3e4b5b); }
  .btn-gradient-dark:hover {
    opacity: .8; }
  .btn-gradient-dark:not(.btn-gradient-light) {
    color: #ffffff; }
    .btn-gradient-dark:not(.btn-gradient-light):hover, .btn-gradient-dark:not(.btn-gradient-light):focus, .btn-gradient-dark:not(.btn-gradient-light):active {
      color: #ffffff; }

/* Breadcrumbs */
.breadcrumb {
	padding: 0
}
  .breadcrumb .breadcrumb-item {
    font-size: 1.25rem;
display: flex;
align-items: center		}
    .breadcrumb .breadcrumb-item.active {
      color: #000 }
  .breadcrumb.breadcrumb-custom {
    padding: 0 0;
    border-color: #dbe3e6; }
    .breadcrumb.breadcrumb-custom .breadcrumb-item {
      font-size: 0.875rem;
      background: #dbe3e6;
      padding: 0.56rem 10px;
      color: #000;
      display: inline-block;
      vertical-align: top; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item:before {
        content: ""; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item a {
        position: relative;
        color: inherit;
        border: 1px solid #dbe3e6;
        display: inline-block;
        vertical-align: top; }
        .breadcrumb.breadcrumb-custom .breadcrumb-item a:before, .breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
          position: absolute;
          top: -9px;
          width: 0;
          height: 0;
          content: "";
          border-top: 21px solid transparent;
          border-bottom: 21px solid transparent; }
        .breadcrumb.breadcrumb-custom .breadcrumb-item a:before {
          right: -22px;
          z-index: 3;
          border-left-color: #dbe3e6;
          border-left-style: solid;
          border-left-width: 12px; }
        .breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
          border-top: 21px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 12px solid #ffffff;
          top: -9px;
          right: -23px; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item span {
        display: inline-block;
        vertical-align: top; }
      .breadcrumb.breadcrumb-custom .breadcrumb-item.active {
        color: rgba(52, 58, 64, 0.8); }
  .breadcrumb.bg-success, .breadcrumb.bg-dark, .breadcrumb.bg-danger, .breadcrumb.bg-warning, .breadcrumb.bg-primary, .breadcrumb.bg-info {
    border: none; }
    .breadcrumb.bg-success .breadcrumb-item, .breadcrumb.bg-dark .breadcrumb-item, .breadcrumb.bg-danger .breadcrumb-item, .breadcrumb.bg-warning .breadcrumb-item, .breadcrumb.bg-primary .breadcrumb-item, .breadcrumb.bg-info .breadcrumb-item {
      color: #ffffff; }
      .breadcrumb.bg-success .breadcrumb-item:before, .breadcrumb.bg-dark .breadcrumb-item:before, .breadcrumb.bg-danger .breadcrumb-item:before, .breadcrumb.bg-warning .breadcrumb-item:before, .breadcrumb.bg-primary .breadcrumb-item:before, .breadcrumb.bg-info .breadcrumb-item:before {
        color: inherit; }
      .breadcrumb.bg-success .breadcrumb-item a, .breadcrumb.bg-success .breadcrumb-item span, .breadcrumb.bg-dark .breadcrumb-item a, .breadcrumb.bg-dark .breadcrumb-item span, .breadcrumb.bg-danger .breadcrumb-item a, .breadcrumb.bg-danger .breadcrumb-item span, .breadcrumb.bg-warning .breadcrumb-item a, .breadcrumb.bg-warning .breadcrumb-item span, .breadcrumb.bg-primary .breadcrumb-item a, .breadcrumb.bg-primary .breadcrumb-item span, .breadcrumb.bg-info .breadcrumb-item a, .breadcrumb.bg-info .breadcrumb-item span {
        color: inherit; }

/* inverse breadcrumb */
.bg-inverse-primary, .image-grouped .text-avatar {
  background: #f6edff;
  border-color: #E8630E; }
  .bg-inverse-primary .breadcrumb-item, .image-grouped .text-avatar .breadcrumb-item {
    color: #E8630E; }
    .bg-inverse-primary .breadcrumb-item:before, .image-grouped .text-avatar .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-primary .breadcrumb-item a, .image-grouped .text-avatar .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-primary.breadcrumb-custom, .image-grouped .breadcrumb-custom.text-avatar {
    background: transparent; }
    .bg-inverse-primary.breadcrumb-custom .breadcrumb-item, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item {
      background: #f6edff; }
      .bg-inverse-primary.breadcrumb-custom .breadcrumb-item a, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a {
        color: #E8630E;
        border: none; }
        .bg-inverse-primary.breadcrumb-custom .breadcrumb-item a:before, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a:before {
          border-left-color: #f6edff; }
      .bg-inverse-primary.breadcrumb-custom .breadcrumb-item span, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item span {
        color: #E8630E; }
      .bg-inverse-primary.breadcrumb-custom .breadcrumb-item:last-child, .image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-secondary {
  background: white;
  border-color: #c3bdbd; }
  .bg-inverse-secondary .breadcrumb-item {
    color: #c3bdbd; }
    .bg-inverse-secondary .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-secondary .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-secondary.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item {
      background: white; }
      .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a {
        color: #c3bdbd;
        border: none; }
        .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: white; }
      .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item span {
        color: #c3bdbd; }
      .bg-inverse-secondary.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-success {
  background: #7ceedd;
  border-color: #77DD77; }
  .bg-inverse-success .breadcrumb-item {
    color: #77DD77; }
    .bg-inverse-success .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-success .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-success.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-success.breadcrumb-custom .breadcrumb-item {
      background: #7ceedd; }
      .bg-inverse-success.breadcrumb-custom .breadcrumb-item a {
        color: #77DD77;
        border: none; }
        .bg-inverse-success.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #7ceedd; }
      .bg-inverse-success.breadcrumb-custom .breadcrumb-item span {
        color: #77DD77; }
      .bg-inverse-success.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-info {
  background: #89c4f2;
  border-color: #E8630E; }
  .bg-inverse-info .breadcrumb-item {
    color: #E8630E; }
    .bg-inverse-info .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-info .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-info.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-info.breadcrumb-custom .breadcrumb-item {
      background: #89c4f2; }
      .bg-inverse-info.breadcrumb-custom .breadcrumb-item a {
        color: #E8630E;
        border: none; }
        .bg-inverse-info.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #89c4f2; }
      .bg-inverse-info.breadcrumb-custom .breadcrumb-item span {
        color: #E8630E; }
      .bg-inverse-info.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-warning {
  background: #ffed92;
  border-color: #fed713; }
  .bg-inverse-warning .breadcrumb-item {
    color: #fed713; }
    .bg-inverse-warning .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-warning .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-warning.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-warning.breadcrumb-custom .breadcrumb-item {
      background: #ffed92; }
      .bg-inverse-warning.breadcrumb-custom .breadcrumb-item a {
        color: #fed713;
        border: none; }
        .bg-inverse-warning.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #ffed92; }
      .bg-inverse-warning.breadcrumb-custom .breadcrumb-item span {
        color: #fed713; }
      .bg-inverse-warning.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-danger {
  background: #fffbfb;
  border-color: #ff3860; }
  .bg-inverse-danger .breadcrumb-item {
    color: #ff3860; }
    .bg-inverse-danger .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-danger .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-danger.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-danger.breadcrumb-custom .breadcrumb-item {
      background: #fffbfb; }
      .bg-inverse-danger.breadcrumb-custom .breadcrumb-item a {
        color: #ff3860;
        border: none; }
        .bg-inverse-danger.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #fffbfb; }
      .bg-inverse-danger.breadcrumb-custom .breadcrumb-item span {
        color: #ff3860; }
      .bg-inverse-danger.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-light {
  background: white;
  border-color: #f8f9fa; }
  .bg-inverse-light .breadcrumb-item {
    color: #f8f9fa; }
    .bg-inverse-light .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-light .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-light.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-light.breadcrumb-custom .breadcrumb-item {
      background: white; }
      .bg-inverse-light.breadcrumb-custom .breadcrumb-item a {
        color: #f8f9fa;
        border: none; }
        .bg-inverse-light.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: white; }
      .bg-inverse-light.breadcrumb-custom .breadcrumb-item span {
        color: #f8f9fa; }
      .bg-inverse-light.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

.bg-inverse-dark {
  background: #778aa2;
  border-color: #3e4b5b; }
  .bg-inverse-dark .breadcrumb-item {
    color: #3e4b5b; }
    .bg-inverse-dark .breadcrumb-item:before {
      color: inherit; }
    .bg-inverse-dark .breadcrumb-item a {
      color: inherit; }
  .bg-inverse-dark.breadcrumb-custom {
    background: transparent; }
    .bg-inverse-dark.breadcrumb-custom .breadcrumb-item {
      background: #778aa2; }
      .bg-inverse-dark.breadcrumb-custom .breadcrumb-item a {
        color: #3e4b5b;
        border: none; }
        .bg-inverse-dark.breadcrumb-custom .breadcrumb-item a:before {
          border-left-color: #778aa2; }
      .bg-inverse-dark.breadcrumb-custom .breadcrumb-item span {
        color: #3e4b5b; }
      .bg-inverse-dark.breadcrumb-custom .breadcrumb-item:last-child {
        background: transparent; }

/* Cards */

  .card .card-body {
    padding: 1rem 1.5rem; }
    .card .card-body + .card-body {
      padding-top: 1rem; }
  .card .card-title {
    color: #343a40;
    margin-bottom: 0.75rem;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    font-size: 1.125rem; }
  .card .card-subtitle {
    font-family: 'Poppins', sans-serif;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .card .card-description {
    color: #76838f;
    margin-bottom: 1.5rem;
    font-family: 'Poppins', sans-serif; }
  .card.card-outline-success {
    border: 1px solid #77DD77; }
  .card.card-outline-primary {
    border: 1px solid #E8630E; }
  .card.card-outline-warning {
    border: 1px solid #fed713; }
  .card.card-outline-danger {
    border: 1px solid #ff3860; }
  .card.card-rounded {
    border-radius: 5px; }
  .card.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2; }
  .card.card-circle-progress {
    color: #ffffff;
    text-align: center; }
  .card.card-img-holder {
    position: relative; }
    .card.card-img-holder .card-img-absolute {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			transform: rotate(180deg);
			opacity: .1;
			filter: invert(1);
		}
  .card.bg-blue-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#065efd), color-stop(#3169fd), to(#6f79fc));
    background: linear-gradient(to right, #065efd, #3169fd, #6f79fc);
    color: #fff; }
  .card.bg-orange-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#ff7f2e), to(#fe7452));
    background: linear-gradient(to right, #ff7f2e, #fe7452);
    color: #fff; }
  .card.bg-green-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#24e8a6), to(#09cdd1));
    background: linear-gradient(to right, #24e8a6, #09cdd1);
    color: #fff; }
  .card.card-no-shadow {
    -webkit-box-shadow: none;
    box-shadow: none; }

.card-inverse-primary {
  background: rgba(182, 109, 255, 0.2);
  border: 1px solid #a764eb;
  color: #8a53c2; }

.card-inverse-secondary {
  background: rgba(195, 189, 189, 0.2);
  border: 1px solid #b3aeae;
  color: #949090; }

.card-inverse-success {
  background: rgba(27, 207, 180, 0.2);
  border: 1px solid #19bea6;
  color: #159d89; }

.card-inverse-info {
  background: rgba(25, 138, 227, 0.2);
  border: 1px solid #177fd1;
  color: #1369ad; }

.card-inverse-warning {
  background: rgba(254, 215, 19, 0.2);
  border: 1px solid #eac611;
  color: #c1a30e; }

.card-inverse-danger {
  background: rgba(254, 124, 150, 0.2);
  border: 1px solid #ea728a;
  color: #c15e72; }

.card-inverse-light {
  background: rgba(248, 249, 250, 0.2);
  border: 1px solid #e4e5e6;
  color: #bcbdbe; }

.card-inverse-dark {
  background: rgba(62, 75, 91, 0.2);
  border: 1px solid #394554;
  color: #2f3945; }

/* Checkboxes and Radios */

  .form-check .form-check-label {
    display: block;
    margin-left: 1.75rem;
    font-size: 0.875rem;
    line-height: 1.5; }
    .rtl .form-check .form-check-label {
      margin-left: 0;
      margin-right: 1.75rem; }
    .form-check .form-check-label input {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0;
      margin-top: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0); }
      .rtl .form-check .form-check-label input {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
      position: absolute;
      top: 0;
      left: 0; }
      .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 2px;
      border: solid #000000;
      border-width: 2px;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms;
      font-family: Material Design Icons;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transform: scale(0);
      transform: scale(0);
      content: '\F12C';
      font-size: .93rem;
      font-weight: bold;
      color: #000000; }
    .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:after {
      width: 18px;
      opacity: 1;
      line-height: 18px;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      transform: scale(1); }
    .form-check .form-check-label input[type="checkbox"]:disabled + .input-helper:before {
      border-color: #ebedf2; }
    .form-check .form-check-label input[type="checkbox"]:disabled:checked + .input-helper:after {
      background: #ebedf2;
      color: #ffffff; }
    .form-check .form-check-label input[type="radio"] + .input-helper:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      border: solid #E8630E;
      border-width: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms; }
      .rtl .form-check .form-check-label input[type="radio"] + .input-helper:before {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="radio"] + .input-helper:after {
      content: "";
      width: 8px;
      height: 8px;
      background: #ffffff;
      border-radius: 50%;
      top: 6px;
      left: 6px;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transform: scale(0);
      transform: scale(0);
      position: absolute; }
      .rtl .form-check .form-check-label input[type="radio"] + .input-helper:after {
        left: auto;
        right: 6px; }
    .form-check .form-check-label input[type="radio"]:checked + .input-helper:before {
      background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
      background: linear-gradient(to right, #da8cff, #9a55ff);
      border-width: 0; }
    .form-check .form-check-label input[type="radio"]:checked + .input-helper:after {
      opacity: 1;
      line-height: 1.5;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      transform: scale(1); }
    .form-check .form-check-label input[type="radio"]:disabled + .input-helper:before {
      border-color: #ebedf2; }
    .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:before {
      background: #ebedf2; }
    .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:after {
      background: #ffffff; }

.form-check-primary.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-primary.form-check label input[type="radio"] + .input-helper:before {
  border-color: #E8630E; }

.form-check-primary.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-primary.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff); }

.form-check-secondary.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-secondary.form-check label input[type="radio"] + .input-helper:before {
  border-color: #c3bdbd; }

.form-check-secondary.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-secondary.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96));
  background: linear-gradient(to right, #e7ebf0, #868e96); }

.form-check-success.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-success.form-check label input[type="radio"] + .input-helper:before {
  border-color: #77DD77; }

.form-check-success.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-success.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae); }

.form-check-info.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-info.form-check label input[type="radio"] + .input-helper:before {
  border-color: #E8630E; }

.form-check-info.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-info.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%); }

.form-check-warning.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-warning.form-check label input[type="radio"] + .input-helper:before {
  border-color: #fed713; }

.form-check-warning.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-warning.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #f6e384, #ffd500); }

.form-check-danger.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-danger.form-check label input[type="radio"] + .input-helper:before {
  border-color: #ff3860; }

.form-check-danger.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-danger.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096); }

.form-check-light.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-light.form-check label input[type="radio"] + .input-helper:before {
  border-color: #f8f9fa; }

.form-check-light.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-light.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9); }

.form-check-dark.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-dark.form-check label input[type="radio"] + .input-helper:before {
  border-color: #3e4b5b; }

.form-check-dark.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-dark.form-check label input[type="radio"]:checked + .input-helper:before {
  background: linear-gradient(89deg, #5e7188, #3e4b5b); }

/* Dropdowns */
.dropdown .dropdown-toggle:after {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font: normal normal normal 24px/1 "Material Design Icons";
  content: "\f140";
  width: auto;
  height: auto;
  vertical-align: baseline;
  font-size: .75rem; }

.dropdown .dropdown-menu {
  margin-top: 0;
  font-size: 0.875rem;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); }
  .dropdown .dropdown-menu .dropdown-item {
    font-size: 1rem;
    padding: .25rem 1.5rem; }
    .dropdown .dropdown-menu .dropdown-item:active {
      background: initial; }
    .dropdown .dropdown-menu .dropdown-item:hover {
      background-color: #f8f9fa;
      color: #000; }

/* Forms */
.form-group {
  margin-bottom: 1.5rem; }

.input-group-append,
.input-group-prepend {
  color: #c9c8c8;
  width: auto;
  border: none; }
  .input-group-append .input-group-text,
  .input-group-prepend .input-group-text {
    border-color: #ebedf2;
    padding: 0.85rem 0.75rem;
    color: #c9c8c8; }

.form-control {
	font-family: 'Poppins', sans-serif;
	font-size: 20px;
	height: 38px;
	padding: 5px 12px;
	box-shadow: none;
}
  .form-control:focus {
    background-color: #ffffff;
    color: #000;
    outline: none; }
.form-control.search-icon {
	font-size: 14px;
	background-image: url(../images/icons/search.svg);
	background-repeat: no-repeat;
	background-position: top 12px left 20px;
	padding-left: 45px;
	height: 42px;
	border-radius: 50px;
}
.form-control.calendar-icon {
	background-image: url(../images/icons/calendar.svg);
	background-repeat: no-repeat;
	background-position: center right 8px;
	padding-right: 38px;
	background-size: 22px
}

select.form-control {
	padding: .4375rem .75rem;
	/*border: 0;*/
	/* color: #c9c8c8; */
}
/* select.form-control {
  padding: .4375rem .75rem;
  border: 0;
  outline: 1px solid #ebedf2;
  color: #c9c8c8; } */
  select.form-control:focus {
    outline: 1px solid #ebedf2; }
  select.form-control.border-primary {
    outline: 1px solid #E8630E; }
    select.form-control.border-primary:focus {
      outline: 1px solid #E8630E; }
  select.form-control.border-secondary, select.form-control.loader-demo-box {
    outline: 1px solid #c3bdbd; }
    select.form-control.border-secondary:focus, select.form-control.loader-demo-box:focus {
      outline: 1px solid #c3bdbd; }
  select.form-control.border-success {
    outline: 1px solid #77DD77; }
    select.form-control.border-success:focus {
      outline: 1px solid #77DD77; }
  select.form-control.border-info {
    outline: 1px solid #E8630E; }
    select.form-control.border-info:focus {
      outline: 1px solid #E8630E; }
  select.form-control.border-warning {
    outline: 1px solid #fed713; }
    select.form-control.border-warning:focus {
      outline: 1px solid #fed713; }
  select.form-control.border-danger {
    outline: 1px solid #ff3860; }
    select.form-control.border-danger:focus {
      outline: 1px solid #ff3860; }
  select.form-control.border-light {
    outline: 1px solid #f8f9fa; }
    select.form-control.border-light:focus {
      outline: 1px solid #f8f9fa; }
  select.form-control.border-dark {
    outline: none; }
    select.form-control.border-dark:focus {
      outline: 1px solid #3e4b5b; }

.form-group label {
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: top;
  margin-bottom: .5rem; }

.form-group.has-danger .form-control {
  border-color: #ff3860; }

.form-group .file-upload-default {
  visibility: hidden;
  position: absolute; }

.form-group .file-upload-info {
  background: transparent; }

/* Icons */
.icons-list {
  border-left: 1px solid #ebedf2;
  border-top: 1px solid #ebedf2; }
  .icons-list > div {
    border-bottom: 1px solid #ebedf2;
    border-right: 1px solid #ebedf2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 15px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem; }
    .icons-list > div i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: #E8630E; }

/* Lists */
ul,
ol,
dl {
  padding-left: 1rem;
  font-size: 0.875rem; }
  ul li,
  ol li,
  dl li {
    line-height: 1.8; }

.list-ticked,
.list-arrow,
.list-star {
  list-style: none;
  padding: 0; }
  .list-ticked li,
  .list-arrow li,
  .list-star li {
    padding-left: 1.5rem; }
    .list-ticked li:before,
    .list-arrow li:before,
    .list-star li:before {
      font-family: "Material Design Icons";
      margin-left: -1.5rem;
      width: 1.5rem;
      margin-right: .5rem; }

.list-ticked li:before {
  content: '\F12D';
  color: #ff3860; }

.list-arrow li:before {
  content: '\F142';
  color: #77DD77; }

.list-star li:before {
  content: '\F4CE';
  color: #fed713; }

.gradient-bullet-list {
  padding-left: 0; }
  .rtl .gradient-bullet-list {
    padding-right: 0; }
  .gradient-bullet-list li {
    position: relative;
    list-style-type: none;
    padding-left: 25px;
    line-height: 1;
    padding-bottom: 25px; }
    .gradient-bullet-list li:before, .gradient-bullet-list li:after {
      content: "";
      position: absolute; }
    .gradient-bullet-list li:before {
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border-radius: 100%; }
    .gradient-bullet-list li:after {
      width: 11px;
      height: 11px;
      top: 2px;
      left: 2px;
      background: #fff;
      border-radius: 100%; }
    .gradient-bullet-list li:nth-child(1):before {
      background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
      background: linear-gradient(to right, #ffbf96, #fe7096); }
    .gradient-bullet-list li:nth-child(2):before {
      background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
      background: linear-gradient(to right, #90caf9, #047edf 99%); }
    .gradient-bullet-list li:nth-child(3):before {
      background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
      background: linear-gradient(to right, #f6e384, #ffd500); }
    .gradient-bullet-list li:nth-child(4):before {
      background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
      background: linear-gradient(to right, #84d9d2, #07cdae); }
    .gradient-bullet-list li:nth-child(5):before {
      background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
      background: linear-gradient(to right, #da8cff, #9a55ff); }

.bullet-line-list {
  padding-left: 30px;
  margin-bottom: 0;
  position: relative;
  list-style-type: none; }
  .rtl .bullet-line-list {
    padding-right: 0px; }
  .bullet-line-list li {
    position: relative;
    line-height: 1;
    padding-bottom: 10px; }
    .bullet-line-list li:before {
      content: "";
      position: absolute;
      border-radius: 100%;
      width: 12px;
      height: 12px;
      left: -28px;
      top: 6px;
      border: 3px solid #E8630E;
      margin-right: 15px;
      z-index: 2;
      background: #ffffff; }
    .bullet-line-list li:after {
      content: "";
      border: 1px solid #ebedf2;
      position: absolute;
      bottom: 0;
      left: -23px;
      height: 100%; }
    .bullet-line-list li:first-child:after {
      content: "";
      height: 80%; }
    .bullet-line-list li:last-child {
      padding-bottom: 0; }
      .bullet-line-list li:last-child:after {
        content: "";
        top: 0;
        height: 30%; }

/* Preview */
.preview-list .preview-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: .75rem 1.5rem;
  font-size: .875rem; }
  .preview-list .preview-item:last-child {
    border-bottom: 0; }
  .preview-list .preview-item:hover {
    background: #f8f9fa; }
  .preview-list .preview-item .form-check {
    margin-top: 8px;
    margin-right: 1rem; }
  .preview-list .preview-item .preview-thumbnail {
    color: #ffffff;
    position: relative; }
    .preview-list .preview-item .preview-thumbnail img,
    .preview-list .preview-item .preview-thumbnail .preview-icon {
      width: 36px;
      height: 36px;
      border-radius: 100%; }
    .preview-list .preview-item .preview-thumbnail .preview-icon {
      padding: 6px;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .preview-list .preview-item .preview-thumbnail .preview-icon i {
        font-size: 1.125rem;
        margin: 0; }
    .preview-list .preview-item .preview-thumbnail .badge {
      border: 2px solid #ffffff;
      border-radius: 100%;
      bottom: 5px;
      display: block;
      height: 14px;
      left: -5px;
      padding: 0;
      position: absolute;
      width: 14px; }
  .preview-list .preview-item .preview-item-content {
    line-height: 1;
    padding-left: 15px; }
    .preview-list .preview-item .preview-item-content:first-child {
      padding-left: 0; }
    .preview-list .preview-item .preview-item-content p {
      margin-bottom: 10px; }
      .preview-list .preview-item .preview-item-content p .content-category {
        font-family: 'source-sans-pro-semibold', sans-serif;
        padding-right: 15px;
        border-right: 1px solid #ebedf2; }
    .rtl .preview-list .preview-item .preview-item-content {
      padding-left: 0;
      padding-right: 1rem;
      margin-right: 0;
      margin-left: auto; }
  .preview-list .preview-item .preview-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .preview-list .preview-item .preview-actions i {
      width: 29px;
      color: #e6e9ed;
      height: 29px;
      border: 2px solid #e6e9ed;
      border-radius: 100%;
      padding: 3px 6px;
      display: inline-block; }
      .preview-list .preview-item .preview-actions i:first-child {
        margin-right: 10px; }

.preview-list.comment-preview .preview-item {
  padding: .87rem 0; }
  .preview-list.comment-preview .preview-item:first-child {
    padding-top: 0; }
  .preview-list.comment-preview .preview-item p {
    line-height: 27px; }

.preview-list.bordered .preview-item {
  border-bottom: 1px solid #ebedf2; }
  .preview-list.bordered .preview-item:last-child {
    border-bottom: 0; }

/* Tables */
.table {
  margin-bottom: 0; }
  .table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    font-family: 'Poppins', sans-serif;
    font-weight: initial; }
    .table thead th i {
      margin-left: 0.325rem; }
  .table th,
  .table td {
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 1;
    white-space: nowrap; }
    .table th .badge,
    .table td .badge {
      margin-bottom: 0; }
  .table.table-borderless {
    border: none; }
    .table.table-borderless tr,
    .table.table-borderless td,
    .table.table-borderless th {
      border: none; }

/* Tabs */
.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden; }
  .add-items input[type="text"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    background: transparent; }
  .add-items .btn {
    margin-left: .5rem; }
    .rtl .add-items .btn {
      margin-left: auto;
      margin-right: .5rem; }

.list-wrapper {
  height: 100%;
  max-height: 100%; }
  .list-wrapper ul {
    padding: 0;
    text-align: left;
    list-style: none;
    margin-bottom: 0; }
    .list-wrapper ul li {
      font-size: .9375rem;
      padding: .4rem 0;
      border-bottom: 1px solid #ebedf2; }
      .list-wrapper ul li:first-child {
        border-bottom: none; }
      .list-wrapper ul li .form-check {
        max-width: 90%;
        margin-top: .25rem;
        margin-bottom: .25rem; }
  .list-wrapper input[type="checkbox"] {
    margin-right: 15px; }
  .list-wrapper .remove {
    cursor: pointer;
    font-size: 1.438rem;
    font-weight: 600;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 20px;
    text-align: center; }
  .list-wrapper .completed {
    text-decoration: line-through;
    -webkit-text-decoration-color: #E8630E;
    text-decoration-color: #E8630E; }

/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
.auth .auth-form-light {
  background: #ffffff; }
  .auth .auth-form-light select {
    color: #c9c8c8; }
  .auth .auth-form-light .input-group .form-control:focus, .auth .auth-form-light .input-group .form-control:active {
    border-color: #ebedf2; }

.auth .brand-logo {
  margin-bottom: 1rem; }
.auth form .form-group {
  margin-bottom: 1.5rem; }
  .auth form .form-group label {
    font-size: .8125rem; }
  .auth form .form-group .form-control {
    background: transparent;
    border-radius: 0;
    font-size: .9375rem; }

.auth form .auth-form-btn {
  height: 50px;
  line-height: 1.5; }

.auth form .auth-link {
  font-size: 0.875rem; }
  .auth form .auth-link:hover {
    color: initial; }

/* Navbar */
.navbar {
  font-family: 'Poppins', sans-serif;
  background: #F9F9FB;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
	width: calc(100% - 300px);
	margin-left: auto;	}
	.sidebar-icon-only .navbar {width: calc(100% - 100px)}
  .navbar .navbar-brand {
    width: 110px; }
  .navbar .navbar-brand-wrapper {
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
    width: 300px;
    height: 100px;}
    @media (max-width: 991px) {
      .navbar .navbar-brand-wrapper {
        width: 55px; } }
    .navbar .navbar-brand-wrapper .navbar-brand {
      color: #27367f;
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: .25rem 0;
      width: 100%; }
      .navbar .navbar-brand-wrapper .navbar-brand:active, .navbar .navbar-brand-wrapper .navbar-brand:focus, .navbar .navbar-brand-wrapper .navbar-brand:hover {
        color: #1b2658; }
      .navbar-brand.brand-logo-mini {
        display: none; }
  .navbar .navbar-menu-wrapper {
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    color: #000;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100%);
    height: 100px;}
    @media (max-width: 991px) {
      .navbar .navbar-menu-wrapper {
        width: auto;
        padding-left: 15px;
        padding-right: 15px; } }
    .navbar .navbar-menu-wrapper .navbar-toggler {
      border: 0;
      color: #000;
      height: 100px;
      border-radius: 0px;
      padding-left: 5px;
      padding-right: 20px; }
      .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
        font-size: 1.5rem; }
        @media (max-width: 991px) {
          .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
            display: none; } }
      @media (max-width: 991px) {
        .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
          padding-left: 15px;
          padding-right: 11px;
          border-right: none; } }
    .navbar .navbar-menu-wrapper .search-field .input-group input {
      font-size: 0.875rem;
      padding: .5rem; }
      .navbar .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
        font-size: 0.875rem;
        color: #9c9fa6;
        font-family: 'Poppins', sans-serif; }
      .navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
        font-size: 0.875rem;
        color: #9c9fa6;
        font-family: 'Poppins', sans-serif; }
      .navbar .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
        font-size: 0.875rem;
        color: #9c9fa6;
        font-family: 'Poppins', sans-serif; }
      .navbar .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
        font-size: 0.875rem;
        color: #9c9fa6;
        font-family: 'Poppins', sans-serif; }
    .navbar .navbar-menu-wrapper .search-field .input-group i {
      font-size: 17px;
      margin-right: 0;
      color: #9c9fa6; }
    .navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
      background: transparent; }
    .navbar .navbar-menu-wrapper .count-indicator {
      position: relative; }
      .navbar .navbar-menu-wrapper .count-indicator .count-symbol,
      .navbar .navbar-menu-wrapper .count-indicator .count-number {
        position: absolute;
        border-radius: 100%;
        border: 2px solid #ffffff; }
      .navbar .navbar-menu-wrapper .count-indicator .count-symbol {
        top: 17px;
        right: -3px;
        width: 10px;
        height: 10px; }
      .navbar .navbar-menu-wrapper .count-indicator .count-number {
        min-width: 14px;
        height: 14px;
        font-size: .5rem;
        color: #ffffff;
        bottom: 16px;
        right: -5px;
        line-height: 1;
        text-align: center; }
      .navbar .navbar-menu-wrapper .count-indicator:after {
        display: none; }
    .navbar .navbar-menu-wrapper .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
        color: inherit;
        font-size: 0.875rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        height: 70px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        @media (max-width: 767px) {
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
            margin-left: .8rem;
            margin-right: .8rem; } }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
          font-size: 1.25rem; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
        color: #E8630E;
        font-size: 1rem; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
        border: none;
        border-radius: 5px;
        -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2); }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
          position: absolute;
          font-size: 0.9rem;
          margin-top: 0;
          padding: 0; }
          .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
            right: auto;
            left: 0; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
            margin-bottom: 0;
            padding: 8px 13px;
            cursor: pointer; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
              font-size: 17px; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item .ellipsis {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis; }
            .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
              margin-left: 10px; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-divider {
            margin: 0; }
      @media (max-width: 991px) {
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
          position: static; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
            left: 20px;
            right: 20px;
            top: 70px;
            width: calc(100% - 40px); } }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
        padding-left: 20px;
        padding-right: 5px; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
          margin-right: 0;
          margin-left: 0; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img {
        position: relative;
        width: 40px;
        height: 40px; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img img {
          width: 40px;
          height: 40px;
          border-radius: 100%; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status {
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          border: 2px solid #ffffff;
          bottom: 5px;
          right: -5px; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.online {
            background: #77DD77; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.offline {
            background: #ff3860; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.busy {
            background: #fed713; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
        margin-left: 1.25rem; }
        .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
          margin-left: 0;
          margin-right: 1.25rem; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text p {
          line-height: 1; }
        @media (max-width: 767px) {
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
            display: none; } }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link.dropdown-toggle:after {
        line-height: 2; }
      @media (min-width: 992px) {
        .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
          margin-left: auto; }
          .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
            margin-left: 0;
            margin-right: auto; } }

@media (max-width: 991px) {
  .navbar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .navbar .navbar-brand-wrapper {
      width: 75px; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
        display: none; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
        display: inline-block; }
  .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.5rem; } }

@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    width: 55px; }
    .navbar .navbar-brand-wrapper .brand-logo-mini {
      padding-top: 0px; } }

/* Navbar color variations */
.navbar.navbar-primary .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-secondary .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96));
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-success .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-info .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-warning .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-danger .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-light .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-dark .navbar-menu-wrapper {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

/* Sidebar */
.sidebar {
  min-height: calc(100vh - 70px);
  background: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  width: 300px;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease; }
  .nav a {
    text-decoration-color: transparent;
  }
  .sidebar .nav {
    overflow: hidden;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 60px;
padding: 0 18px;
    margin-top: 30px;		}
    .sidebar .nav .nav-item {
      padding: 0 1.5rem;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s;
      transition-property: background;
      -webkit-transition-property: background;
margin-bottom: 10px			}
      .sidebar .nav .nav-item .collapse {
        z-index: 999; }
      .sidebar .nav .nav-item .nav-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        white-space: nowrap;
        padding: 1.125rem 0 1.125rem 0;
        color: #fff;
        -webkit-transition-duration: 0.45s;
        transition-duration: 0.45s;
        transition-property: color;
        -webkit-transition-property: color; }
        .sidebar .nav .nav-item .nav-link i {
          color: inherit; }
          .sidebar .nav .nav-item .nav-link i.menu-icon {
            font-size: 1.125rem;
            line-height: 1;
            margin-left: auto;}
            .rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
              margin-left: 0;
              margin-right: auto; }
            .sidebar .nav .nav-item .nav-link i.menu-icon:before {
              vertical-align: middle; }
          .sidebar .nav .nav-item .nav-link i.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: 1.125rem;
            margin-left: auto;}
            .rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
              margin-left: 0;
              margin-right: auto; }
            .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
              content: "\f141";
              font-size: inherit;
              color: inherit; }
            .sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
              margin-left: .25rem; }
              .rtl .sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
                margin-left: 0;
                margin-right: .25rem; }
        .sidebar .nav .nav-item .nav-link .menu-title {
          color: #000;
          display: inline-block;
          font-size: 0.875rem;
          line-height: 1;
          vertical-align: middle; }
        .sidebar .nav .nav-item .nav-link .badge {
          margin-right: auto;
          margin-left: 1rem; }
        .sidebar .nav .nav-item.active .nav-link:not(.collapsed) .menu-arrow:before,
        .sidebar .nav .nav-item .nav-link[aria-expanded="true"] .menu-arrow:before {
          content: "\f140"; }
					.sidebar .nav .nav-item {
						border-radius: 13px;
					}
      .sidebar .nav .nav-item.active,
			.sidebar .nav .nav-item:hover {
				background: #d9d9d9;
    border-color: #b1b2b5;
    color: #000 !important;
			}
				.sidebar .nav .nav-item.active a {
				color: #fff
				}
				.sidebar .nav .nav-item:hover a {
    color: #fff;
}
      .sidebar .nav .nav-item.nav-profile .nav-link {
        height: auto;
        line-height: 1;
        border-top: 0;
        padding: 1.25rem 0; }
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image {
          width: 44px;
          height: 44px; }
          .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image img {
            width: 44px;
            height: 44px;
            border-radius: 100%; }
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
          margin-left: 1rem; }
          .rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
            margin-left: auto;
            margin-right: 1rem; }
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
          font-size: 1.125rem;
          margin-left: auto; }
          .rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
            margin-left: 0;
            margin-right: auto; }
      .sidebar .nav .nav-item.sidebar-actions {
        margin-top: 1rem; }
        .sidebar .nav .nav-item.sidebar-actions .nav-link {
          border-top: 0;
          display: block;
          height: auto; }
        .sidebar .nav .nav-item.sidebar-actions:hover {
          background: initial; }
          .sidebar .nav .nav-item.sidebar-actions:hover .nav-link {
            color: initial; }
    
    .sidebar .nav.sub-menu {
      margin-bottom: 20px;
      margin-top: 0;
      list-style: none; }
      .sidebar .nav.sub-menu .nav-item {
        padding: 0; }
        .sidebar .nav.sub-menu .nav-item .nav-link {
          padding: 0.75rem 2rem 0.75rem 2rem;
          position: relative;
          font-size: 0.8125rem;
          line-height: 1;
          height: auto;
          border-top: 0; }
          .sidebar .nav.sub-menu .nav-item .nav-link:before {
            content: "\F054";
            font-family: "Material Design Icons";
            display: block;
            position: absolute;
            left: 0px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: .75rem; }
            .rtl .sidebar .nav.sub-menu .nav-item .nav-link:before {
              left: auto;
              right: 0; }
          .sidebar .nav.sub-menu .nav-item .nav-link.active {
            /* color: #E8630E; */
            background: transparent; }
        .sidebar .nav.sub-menu .nav-item:hover {
          background: transparent; }

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 100px);
    top: 100px;
    bottom: 0;
    overflow: auto;
    left: -300px;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out; }
    .sidebar-offcanvas.active {
      left: 0; } }

/* Layouts */
.navbar.fixed-top + .page-body-wrapper .main-panel {
  padding-top: 100px; }

@media (min-width: 992px) {
  .sidebar-icon-only .navbar .navbar-brand-wrapper {
    width: 100px; }
    .sidebar-icon-only .brand-logo {
      display: none; }
    .sidebar-icon-only .brand-logo-mini {
      display: inline-block; }
  .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: 100%; }
  .sidebar-icon-only .sidebar {
    width: 100px; }
    .sidebar-icon-only .sidebar .nav {
      overflow: visible; }
      .sidebar-icon-only .sidebar .nav .nav-item {
        position: relative;
        padding: 0; }
        .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
          display: block;
          text-align: center; }
					.sidebar-icon-only .sidebar .nav .nav-item:hover .nav-link span,
					.sidebar-icon-only .sidebar .nav .nav-item .nav-link:hover span {
						color: #fff
					}
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge, .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
            display: none; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
            border-radius: 0 5px 5px 0px; }
            .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
              border-radius: 5px 0 0 5px; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
            margin-right: 0;
            margin-left: 0; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
            display: none; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
            border-radius: 0 5px 0 0px; }
            .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
              border-radius: 5px 0 0 0; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-category {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-doc {
          margin: 0; }
          .sidebar-icon-only .sidebar .nav .nav-item.nav-doc i {
            display: block; }
        .sidebar-icon-only .sidebar .nav .nav-item .collapse {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          color: #000;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          background: #230d00;
          padding: 0.5rem 1.4rem;
          left: 62px;
          position: absolute;
          text-align: left;
          top: 0;
          bottom: 0;
          width: 215px;
          z-index: 1;
          line-height: 1.8; }
          .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
            left: auto;
            right: 62px;
            color: #000;
            text-align: left; }
          .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title:after {
            display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
          display: block;
          padding: .5rem 0;
          background: #230d00;
          border-radius: 0 0 5px 0;
          position: absolute;
          left: 62px;
          width: 215px; }
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse .sub-menu a {
					color: #fff
				}
					
          .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse, .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
            left: auto;
            right: 62px;
            border-radius: 0 0 0 5px; }
      .sidebar-icon-only .sidebar .nav.sub-menu {
        padding: 0 0 0 1.5rem; }
        .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
          text-align: left;
          padding-left: 20px; }
        .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
          margin-right: auto;
          margin-left: 0; }
          .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
            text-align: right; }
            .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
              left: 0;
              right: unset; }
    .sidebar-icon-only .sidebar .sidebar-actions {
      display: none; }
  .sidebar-icon-only .main-panel {
    width: calc(100% - 62px); } }

.rtl {
  direction: rtl;
  text-align: right; }
  .rtl .sidebar .nav {
    padding-right: 0; }
  .rtl .product-chart-wrapper::-webkit-scrollbar,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar,
  .rtl .table-responsive::-webkit-scrollbar,
  .rtl ul.chats::-webkit-scrollbar {
    width: 0.5em; }
  .rtl .product-chart-wrapper::-webkit-scrollbar-track,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar-track,
  .rtl .table-responsive::-webkit-scrollbar-track,
  .rtl ul.chats::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .rtl .product-chart-wrapper::-webkit-scrollbar-thumb,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb,
  .rtl .table-responsive::-webkit-scrollbar-thumb,
  .rtl ul.chats::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey; }

.page-body-wrapper {
  min-height: calc(100vh - 70px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0; }
  .page-body-wrapper.full-page-wrapper {
    width: 100%;
    min-height: 100vh; }

.main-panel {
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 300px);
  min-height: calc(100vh);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media (max-width: 991px) {
    .main-panel {
      margin-left: 0;
      width: 100%; } }

.content-wrapper {
  background: #fff;
  padding: 1.5rem;
  /* padding: 2.75rem 2.25rem; */
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.login-bg {
	background: #f5f8fb;
	position: relative
}
.login-bg .auth-form-light {
    padding: 0 3.75rem;
    position: relative;
    width: 480px;
}
.login-bg .form-control {
	height: 60px;
	border-radius: 55px;
	border: 1px solid #1B1D2180;
	font-size: 16px;
	padding: 5px 26px;
}
.logo-strip.flex-fill {
	text-align: center;
	padding: 25px 15px;
	position: relative
}
.btn-default,
.btn-default:hover {
	background: #000000;
	color: #fff;
	display: inline-block;
	border-width: 2px;
}
.btn.py-2 {
	padding-top: 9px !important;
	padding-bottom: 9px !important;
}
.loginLeft img {
	max-width: 100%;
}
.loginRight {
	background: #fff;
	padding: 30px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border-radius: 10px;
}
.calendar .input-group-text {
	padding: 0 4px;
}
.calendar .input-group-text i[class*="mdi-chevron"] {
	font-size: 30px;
	color: #ababab;
}
.input-group.calendar .form-control {
	z-index: 1;
	background: transparent;
}
.input-group.calendar .icon {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;
	display: flex;
	align-items: center;
	font-size: 20px;
	color: #a660ff;
}
table .action a {
	display: inline-block;
	vertical-align: middle;
}
table .action a i.mdi {
	font-size: 22px;
}
.form-group label.error {
	color: #f00;
	display: block;
	margin-top: 5px;
}
.dataTables_filter input, .dataTables_length select {
	border: 1px solid #dadce0;
	padding: 5px;
	margin-left: 5px;
}
table thead th span {
	color: #000;
	font-family: 'Poppins', sans-serif;
	font-weight: 600 !important;
}
.table.no-bordered tbody td {
	border: none;
}
.table-filter {
	display: none
}
.tableSearch input {
	border: none;
	height: 42px;
	border-radius: 8px;
	padding: 0 15px 0 50px;
	background: #fff url(../images/icn_search.png) no-repeat center left 15px / 18px;
	box-shadow: 0 0 8px rgba(0,0,0,.1);
}
table td {
	font-size: 15px !important;
}
.card .card-body h3.title {
	font-size: 1.4rem;
	font-weight: 600;
	padding: 0 5px;
}
.form-group.d-flex.otp input {
	margin: 0 12px 0 0;
	font-size: 30px;
	text-align: center;
	width: 48px;
}
div#mask {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.2);
}
.form-control::-webkit-input-placeholder {color: #000000;opacity: 0.6;}
.form-control::-moz-placeholder {color: #000000; opacity: 0.6;}
.form-control:-ms-input-placeholder {color: #000000; opacity: 0.6;}
.form-control:-moz-placeholder {color: #000000; opacity: 0.6;}
.password-field {
	position: relative;
}
.password-field i.showPassword {
	position: absolute;
	top: 18px;
	right: 20px;
	cursor: pointer;
	z-index: 1;
}
.password-field i.showPassword:after {
	content: "\F208";
	font: normal normal normal 24px/1 "Material Design Icons";
}
.password-field i.showPassword.inActive:after {
	content: "\F209";
}
.filter .dropdown-toggle,
.filter .dropdown-toggle:hover {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 150px;
	background: #fff;
	border: 1px solid #1B1D2126;
	height: 42px;
	border-radius: 50px;
	padding: 0 20px;
	font-size: 14px;
	color: #000000;
	text-decoration: none
}
.filter .dropdown-toggle img {
	height: 15px;
	transform: rotateY(180deg);
	margin-left: 5px;
}
.filter .dropdown-toggle:after {
	display: none;
}
input[type='checkbox'].switch {
	--active: #275EFE;
	--active-inner: #fff;
	--focus: 2px rgba(39, 94, 254, .3);
	--border: #BBC1E1;
	--border-hover: #275EFE;
	--background: #fff;
	--disabled: #F6F8FF;
	--disabled-inner: #E1E6F9;
	-webkit-appearance: none;
	-moz-appearance: none;
	height: 28px;
	outline: none;
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin: 0;
	cursor: pointer;
	border: 1px solid var(--bc, var(--border));
	background: var(--b, var(--background));
	-webkit-transition: background .3s, border-color .3s, box-shadow .2s;
	transition: background .3s, border-color .3s, box-shadow .2s;
}
input[type='checkbox'].switch:after {
	content: '';
	display: block;
	left: 0;
	top: 0;
	position: absolute;
	-webkit-transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
	transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
	transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
	transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
}
input[type='checkbox'].switch:checked {
	--b: var(--active);
	--bc: var(--active);
	--d-o: .3s;
	--d-t: .6s;
	--d-t-e: cubic-bezier(.2, .85, .32, 1.2);
}
.switchbox.green input[type='checkbox'].switch:checked {
	--b: #64a657;
	--bc: #64a657;
}
input[type='checkbox'].switch:disabled{
	--b: var(--disabled);
	cursor: not-allowed;
	opacity: .9;
}
input[type='checkbox'].switch:disabled:checked {
	--b: var(--disabled-inner);
	--bc: var(--border);
}
input[type='checkbox'].switch:disabled + label {
	cursor: not-allowed;
}
input[type='checkbox'].switch:hover:not(:checked):not(:disabled){
	--bc: var(--border-hover);
}
.green input[type='checkbox'].switch:hover:not(:checked):not(:disabled){
	--bc: inherit;
}
input[type='checkbox'].switch:not(.switch) {
	width: 21px;
}
input[type='checkbox'].switch:not(.switch):after {
	opacity: var(--o, 0);
}
input[type='checkbox'].switch:not(.switch):checked {
	--o: 1;
}
input[type='checkbox'].switch {
	width: 55px;
	border-radius: 50px;
}
input[type='checkbox'].switch:after {
	left: 2px;
	top: 1px;
	border-radius: 100px;
	width: 24px;
	height: 24px;
	background: var(--ab, var(--border));
	-webkit-transform: translateX(var(--x, 0));
	transform: translateX(var(--x, 0));
}
input[type='checkbox'].switch:checked {
	--ab: var(--active-inner);
	--x: 26px;
}
input[type='checkbox'].switch:disabled:not(:checked):after {
	opacity: .6;
}
.switchbox.sm input[type='checkbox'].switch:checked {
	--ab: var(--active-inner);
	--x: 16px;
}
.switchbox.sm input:after {
	width: 14px;
	height: 14px;
}
.switchbox.sm input {
	height: 18px;
	width: 34px;
}

.rhombusIconList ul {
	display: flex;
	align-items: center;
	list-style: none;
	margin: 0 -8px;
	padding: 0;
	flex-wrap: wrap
}
.rhombusIconList ul li {
	width: 36px;
	height: 36px;
	border: 1px solid #1a34b8;
	margin: 12px 14px;
	transform: rotate(45deg);
}
.rhombusIconList ul li.active {
	background: #d6dbf2;
}
.form-control.inline {
	display: inline-block !important;
	width: initial;
}
.form-control.line,
.form-control.line:focus {
	outline: none;
	border-bottom: 1px solid #000;
	padding-left: 0;
	border-radius: 0;
	color: #000;
}
table .action a i.mdi {
	font-size: 20px;
}
.image-card {
	position: relative;
}
.image-card .image {
	border: 1px solid #c7c7c7;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.image-card .image {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}
.image-card.large .image {
	height: 228px;
}
.image-card.medium .image {
	height: 150px;
}
.image-card.small .image {
	height: 125px;
}
.image-card .image-upload {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}
.image-card .image-upload input[type="file"] {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	cursor: pointer;
	z-index: 1
}
.image-card .image-upload b {
	position: relative;
	top: 2px;
	margin-left: 5px;
}
.image-card.addedImage .image-upload {
	position: relative;
	margin-top: 10px;
	justify-content: start;
}
.checkboxList.inline .form-check {
	display: inline-block;
}
.checkboxList .form-check {
	margin: 7px 0;
}
.checkboxList .form-check label {
	margin: 0;
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer
}
.checkboxList .form-check label input {
	display: none;
}
.checkboxList .form-check label em {
	width: 20px;
	height: 20px;
	border: 1px solid #c1c1c1;
	margin-right: 10px;
	position: relative
}
.checkboxList .form-check label input:checked+em:after {
	content: "\2713";
	position: absolute;
	top: -5px;
	right: -2px;
	font-size: 23px;
	font-weight: 500;
}
.checkboxList .form-check label input:checked+em {
	border-color: #000;
}
ul.check-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
ul.check-list.bold li {
	font-weight: 600
}
ul.check-list li {
	position: relative;
	padding-left: 35px;
}
ul.check-list li:before {
	content: "\F12C";
	position: absolute;
	top: 3px;
	left: 0;
	font: bold 22px "Material Design Icons";
}
.text-decoration {
	text-decoration: underline;
}
ul.check-list.h4 li:before {
	top: 5px;
}
.uploadImgIcon {
	position: relative;
}
.uploadImgIcon .imgIcon {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.uploadImgIcon .imgIcon input[type="file"] {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	z-index: 1;
}
.uploadImgIcon .imgIcon .mdi {
	font-size: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	height: 100%;
	padding: 0 8px;
}
.img-content {
	display: flex;
	align-items: center;
}
table .img-content .sm-img {
	width: 40px;
	height: 40px;
	background: #e7e7e8;
	margin-right: 10px;
	overflow: hidden
}
table .img-content .sm-img img {
	object-fit: contain;
	max-height: 40px;
}
table .img-content .text {
	width: 170px;
	white-space: normal;
	line-height: 18px;
}
.count-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
}
.count-pagination ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
}
.count-pagination ul a {
	color: #000;
	font-size: 18px;
	font-weight: 600;
	margin: 0 4px;
}
.count-pagination i.mdi {
	font-size: 24px;
	position: relative;
	top: 1px;
	margin: 0 3px;
}
.rating.lg {
	font-size: 48px
}
input.title {
	border: none;
	font-weight: 800;
}
input.title::-webkit-input-placeholder {
	color: #c9c8c8;
	opacity: 1; }
input.title::-moz-placeholder {
	color: #c9c8c8;
	opacity: 1; }
input.title:-ms-input-placeholder {
	color: #c9c8c8;
	opacity: 1; }
input.title::-ms-input-placeholder {
	color: #c9c8c8;
	opacity: 1; }
input.title::placeholder {
	color: #c9c8c8;
	opacity: 1;
}
.last-paragraph-no-margin p:last-child {
	margin-bottom: 0
}
.tab-card nav {
	position: relative;
	z-index: 1;
}
.tab-card nav a.nav-item,
.tab-card nav a.nav-item:hover {
	border: none;
	font-weight: 600;
	padding: 15px 20px;
	min-width: 150px;
	text-align: center;
	color: #000;
	position: relative
}
.tab-card nav a.nav-item.active:first-child:after {
	content: "";
	position: absolute;
	right: 0;
	bottom: -8px;
	left: 0;
	height: 8px;
	background: #fff;
}
textarea.form-control.editor {
	min-height: 70px;
	border: 2px solid #e2e2e2;
	padding: 15px;
}
.accordionTable .content > td {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	border-top: none
}
.table.accordionTable tr .accordionToggle img {
	-webkit-transition: all .2s ease;
		-moz-transition: all .2s ease;
			transition: all .2s ease;
}
.table.accordionTable tr.active .accordionToggle img {
	transform: rotate(-180deg);
}
table .video-thumbnail {
	width: 140px;
	border: 2px solid #e7e7e7;
	position: relative
}
table .video-thumbnail img {
	max-width: 100%
}
table .video-thumbnail .playButton {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.videoFrame {
	border: 4px solid #f2f2f2
}
.nav-notification a.nav-link i {
	font-size: 28px !important;
}
.nav-notification a.nav-link {
	position: relative
}
.nav-notification a.nav-link em.count {
	position: absolute;
	top: 16px;
	right: -6px;
	width: 20px;
	height: 20px;
	background: #F00;
	border-radius: 50px;
	color: #fff;
	font-style: normal;
	line-height: 18px;
	text-align: center;
}
.nav-notification .dropdown-menu {
	width: 400px;
	padding: 0;
}
.nav-notification .dropdown-menu .menu-header-content {
	padding: 20px 15px;
	text-align: center;
	border-bottom: 1px solid #ececec;
}
.nav-notification .dropdown-menu .menu-body {
	padding: 20px 25px;
	max-height: 250px;
	overflow-y: auto;
}
.nav-notification .dropdown-menu .menu-body .item {
	margin-bottom: 10px;
	position: relative;
	padding-left: 10px;
}
.nav-notification .dropdown-menu .menu-body .item:last-child {
	margin-bottom: 0 !important
}
.nav-notification .dropdown-menu .menu-body .item:before {
	content: "\F13E";
	position: absolute;
	top: -8px;
	left: -17px;
	font-family: Material Design Icons;
	font-size: 20px;
}
.nav-notification .dropdown-menu .menu-body .item p {
	margin-bottom: 0;
	font-size: 14px;
}
.nav-notification .dropdown-menu .menu-body .item .dateTime {
	color: #959595;
}
.nav-notification .dropdown-menu .menu-footer {
	padding: 15px;
	text-align: center;
	border-top: 1px solid #ececec;
}
.nav-tabs .nav-link, .nav-tabs .nav-link:hover {
	font-weight: 500;
	border-radius: 0;
	color: #1B1D217F;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover {
	background: #E8630E;
	color: #fff;
	border-radius: 50px;
}
.chart-tabs .filter {
	position: absolute;
	top: 20px;
	right: 25px;
	font-size: 15px;
}
.chart-tabs .filter select {
	border: 1px solid #d6d6d6;
	font-size: 12px;
	padding: 2px 15px 2px 0;
	color: #9e9e9e;
	margin-left: 5px;
}
.chart.yLable span {
	transform: rotate(-90deg);
	white-space: nowrap;
	width: 40px;
	margin-right: 16px;
}
.weight-400:-ms-input-placeholder {font-weight: 400}.weight-400:-moz-placeholder {font-weight: 400}.weight-400::-moz-placeholder {font-weight: 400}.weight-400::-webkit-input-placeholder {font-weight: 400}
.weight-500:-ms-input-placeholder {font-weight: 500}.weight-500:-moz-placeholder {font-weight: 500}.weight-500::-moz-placeholder {font-weight: 500}.weight-500::-webkit-input-placeholder {font-weight: 500}
.weight-600:-ms-input-placeholder {font-weight: 600}.weight-600:-moz-placeholder {font-weight: 600}.weight-600::-moz-placeholder {font-weight: 600}.weight-600::-webkit-input-placeholder {font-weight: 600}
.weight-700:-ms-input-placeholder {font-weight: 700}.weight-700:-moz-placeholder {font-weight: 700}.weight-700::-moz-placeholder {font-weight: 700}.weight-700::-webkit-input-placeholder {font-weight: 700}
.weight-800:-ms-input-placeholder {font-weight: 800}.weight-800:-moz-placeholder {font-weight: 800}.weight-800::-moz-placeholder {font-weight: 800}.weight-800::-webkit-input-placeholder {font-weight: 800}
.cross-after {
	position: relative;
}
.close.position-absolute {
	opacity: 1;
	right: -50px;
	top: 0;
	bottom: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.color-list li {
	position: relative;
	display: flex;
	align-items: center
}
.color-list li:before {
	content: "";
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
	background: var(--color);
	border-radius: 5px;
	margin-right: 8px
}
.avatar.md .inner,
.avatar.lg .inner,
.avatar.xl .inner {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 100px;
}
.avatar.lg .inner {
	width: 80px;
	height: 80px;
}
.avatar.xl .inner {
	width: 100px;
	height: 100px;
}
.avatar.md-2 .inner {
	border-radius: 14px;
	overflow: hidden;
	height: 60px;
	width: 60px;
	display: flex;
	align-items: end;
	justify-content: center;
}
.avatar.md-2 .inner img {
	max-height: 60px
}
.avatar.bordered .inner {
	border: 4px solid #fff;
}
.avatar.md .inner img {
	max-height: 50px;
}
.card-table table {
	border-collapse: collapse;
}
.card-table table.table-striped tr td:first-child,
.card-table table.table-striped tr th:first-child {
	padding-left: 25px;
}
.btn-8 {
	width: 80px;
	padding: 6px 5px !important;
	font-size: 10px !important;
	font-weight: 600;
	line-height: 1;
	font-size: 12px;
	text-align: center;
	display: inline-block
}
.card-table.round {
	border-radius: 15px
}
.card-table.round .table {
	border: none;
	border-collapse: separate;
	border-spacing: 0;
}
.card-table.round .table tr:last-child td:first-child {
	border-radius: 0 0 0 15px;
}
.card-table.round .table tr:last-child td:last-child {
	border-radius: 0 0 15px 0;
}
.card-table.round .table thead tr th:first-child {
	border-radius: 15px 0 0 0;
}
.card-table.round .table thead tr th:last-child {
	border-radius: 0 15px 0 0;
}
.status option:hover {
	background: #000
}
.status {
	appearance: none;
	background: url(../images/icons/arrow-ios-down.svg) no-repeat center right / 15px;
	padding-right: 25px !important;
}
select.lg {
  appearance: none;
  background: url(../images/icons/arrow-ios-down.svg) no-repeat center right 16px;
}
.avatar .edit {
	position: absolute;
	right: 0;
	bottom: 0;
	border-radius: 50px;
	overflow: hidden
}
.avatar .edit input[type="file"] {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	cursor: pointer;
	opacity: 0;
}
.form-control.lg {
	font-size: 16px;
	height: 50px;
	padding: 5px 24px;
}
.form-control.title.lg {
	font-size: 20px;
	font-weight: 400;
	border: 1px solid #d0d0d0;
	border-radius: 50px;
	padding: 10px 30px;
	height: 56px;
}
.status option[value="active"]{color: #77DD77 !important }
.status option[value="inactive"]{color: #ff3860 !important }
.bg-sky {background: #DFF6FF}
.modal-dialog.modal-md {max-width: 410px;}
.modal-content.rounded {
	border-radius: 30px !important;
}
.btn-secondary-transparent {
	border: 1px solid #0000005C;
	color: #0000005C;
}
.btn-secondary-transparent:focus,
.btn-secondary-transparent:hover {
	background: #0000005C;
	color: #fff;
}
.bg-sky-2 {
	background: #DFF6FF
}
.form-control .input-group-append .cent {
	border-radius: 0 30px 30px 0;
	border-left: 1px solid #000;
}
.filter .dropdown-menu {
	width: 550px;
	padding: 25px 25px;
	box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
	border-radius: 10px;
}
.form-control.sm {
	font-size: 14px;
	border-radius: 50px;
	padding: 0 20px 0 22px;
}
.card-table.round.data-table {
	box-shadow: none
}
.card-table.round.data-table table {
	box-shadow: 0px 2px 8px #E8630E14;
	border-radius: 22px;
	margin-bottom: 12px !important;
}
.dataTables_filter input[type="search"] {
	border-radius: 50px;
}
.dataTables_length {
	font-size: 14px;
	margin: 7px 0;
}
.dataTables_length label {
	margin: 0;
}
.dataTables_length select {
	border-radius: 6px;
	font-size: 14px;
	padding: 4px 10px !important;
	-webkit-appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 5px;
	text-overflow: '';
}
.dataTables_info {
	font-size: 13px;
}
.dataTables_paginate {
	font-size: 14px;
}
.dataTables_paginate span .paginate_button {
	border: 1px solid #cacaca !important;
	border-radius: 60px !important;
	width: 35px;
	line-height: 24px;
	height: 35px;
	background: transparent !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	background: #0eabe9 !important;
	color: #ffffff !important;
	border-color: #0eabe9 !important;
}
.drag-box  {
	min-height: 100px;
	background: transparent;
	border: 2px dashed #d0d0d0;
	border-radius: 10px;
	padding: 4rem 0;
	position: relative;
	text-align: center;
}
.drag-box input[type="file"],
.drag-box input[type="file"]::-webkit-file-upload-button {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	cursor: pointer;
	width: 100%;
	right: 0;
	opacity: 0;
}
.drag-box .btn {
	position: relative;
}
.dropdown.bootstrap-select > button.btn,
.dropdown.bootstrap-select > button.btn:hover,
.dropdown.bootstrap-select > button.btn:focus {
	padding: 15px 20px;
	border: 1px solid #000;
	border-radius: 50px !important;
	background: transparent;
	color: #000;
	outline: none !Important;
	box-shadow: none !Important
}
.dropdown.bootstrap-select > button.btn div {
	overflow: visible !important;
}
.cke_top {
	margin-bottom: 0 !important;
	border: 1px solid #ced4da !important;
	border-radius: 10px 10px 0 0 !important;
	border-bottom: none !important;
	position: relative !important;
	top: 3px !important;
}
@media (max-width: 767px) {
	.close.position-absolute {
		right: -30px
	}
	.logo-strip.flex-fill {
    padding: 8px 15px;
	}
	.brand-logo img {
    height: 70px
	}
	.loginRight {
		background: transparent;
    padding: 2.5rem;
		flex: 1;
		max-width: 100%;
	}
	.loginRight h2 {
    font-size: 22px;
  }
	.loginRight h2:before {
    width: 20px;
    margin-right: 5px;
  }
	.loginRight h2:after {
    width: 20px;
    margin-left: 5px;
  }
	.login-bg .flex-grow {
    flex-direction: column
	}
	.login-bg .auth-form-light {
    padding: 2.5rem 1rem 2rem;
    border: 3px solid #fff;
    max-width: 320px;
	  margin: 2rem auto;
		background: #fff;
		border-radius: 20px
	}
	.login-bg .auth-form-light:before {
    top: -25px;
    /* background: url(../images/top-tape-1.png) no-repeat center / 50px; */
    width: 45px;
    height: 50px;
    margin: auto;
	}
	.login-bg .auth-form-light:after {
		height: 55px;
    background-size: 55px;
	}
	.form-control {
    font-size: 16px;
    height: 42px;
  }
	.form-check {
    margin-top: 10px;
    margin-bottom: 10px;
  }
	.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
    width: 15px;
    height: 15px;
	}
	.form-check .form-check-label {
    margin-left: 1.2rem;
    font-size: 0.775rem;
  }
	.auth-link {
    font-size: 12px;
	}
}
.br-18{
  border-radius: 18px !important;
}
.faq-actions{
  position: absolute;
  top: 15px;
  right: 15px;
}
.wh-12{
width: 12px;
height: 12px;
border-radius: 50%;
display: inline-block;
}
.text-left.dropdown-toggle::after{
  float: right;
}
.scroll-185{
  height: 185px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}
.scroll-thin::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.scroll-thin::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-thin::-webkit-scrollbar-thumb {
  background: #FF984D;
  border-radius: 30px;
}

/* Handle on hover */
.scroll-thin::-webkit-scrollbar-thumb:hover {
  background: #555;
}
label > input[type='text'] {
  width: 100%;
  height: 100%;
  border: 0;
  text-align: center;
}
.close-abs{
  position: absolute;
  right:10px;
  top:10px;
  color: #E75D0F;
  opacity: 1;
}